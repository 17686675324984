import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'fgb-contacts-page',
  templateUrl: './contacts-page.component.html',
  styleUrls: ['./contacts-page.component.scss']
})
export class ContactsPageComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit() {
  }

  BackToFixtures() {
    this.router.navigate(['ticketTransfer']);
  }
}
