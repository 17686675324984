import { Component, OnInit, OnDestroy } from '@angular/core';
import { TransactionSuccessQuery, TransactionSuccessService, LottoItem } from '@fgb/core';

@Component({
  selector: 'fgb-lottos-success-page',
  templateUrl: './lottos-success-page.component.html',
  styleUrls: ['./lottos-success-page.component.scss'],
})
export class LottosSuccessPageComponent implements OnInit, OnDestroy {
  public lottoItem: LottoItem;
  public totalPrice: number;
  public quantity: number;

  constructor(
    private transactionSuccessQuery: TransactionSuccessQuery,
    private transactionSuccessService: TransactionSuccessService
  ) {}

  ngOnInit() {
    const transaction = this.transactionSuccessQuery.getValue();
    this.lottoItem = transaction.item as LottoItem;
    this.totalPrice = transaction.totalPrice;
    this.quantity = transaction.quantity;
  }

  ngOnDestroy(): void {
    this.transactionSuccessService.clear();
  }
}
