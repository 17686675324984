import { Component, OnInit } from '@angular/core';
import { LottoItem, LottoQuery } from '@fgb/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'fgb-lottos-terms-page',
  templateUrl: './lottos-terms-page.component.html',
  styleUrls: ['./lottos-terms-page.component.scss'],
})
export class LottosTermsPageComponent implements OnInit {
  lottoItem: LottoItem | undefined;
  constructor(private lottoQuery: LottoQuery, private route: ActivatedRoute) {}

  ngOnInit() {
    let id = +(this.route.snapshot.paramMap.get('id') || 0);
    this.lottoItem = this.lottoQuery.getEntity(id);
  }
}
