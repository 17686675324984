<div class="banner" role="banner">
  <h1>{{ 'rewards.nav.products' | translate }}</h1>
</div>

<div class="container my-3 d-lg-none">
  <fgb-member-card></fgb-member-card>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="container">
  <fgbcl-marketplace-list [isList]="true" [marketplaceItemTypes]="[4]" [productsItemView]="true"></fgbcl-marketplace-list>
</div>
