import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {
  CardToken,
  LoggingService,
  BalanceService,
  config,
  TopupTicketStore,
  TopupTicketService,
  TopUpTicketPostModel,
  TicketWithBalanceModel,
  TopUpTicketsStatus,
  ManageTicketStatusModel
} from '@fgb/core';

@Component({
  selector: 'fgb-ticket-details-loaded',
  templateUrl: './ticket-details-loaded.component.html',
  styleUrls: ['../ticket-details.scss']
})
export class TicketDetailsLoadedComponent implements OnInit, OnChanges {
  @Input() ticketItem: TicketWithBalanceModel;
  @Input() displayAsTableItem: boolean;
  topUpPostItem: TopUpTicketPostModel;
  topUpAmountSelected: number;
  activeCard: CardToken;

  constructor(
    private topupTicketStore: TopupTicketStore,
    private ticketsService: TopupTicketService,
    private logService: LoggingService,
    private balanceService: BalanceService
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    this.setupTopUpItem();
  }

  get topUpTicketsStatus() {
    return TopUpTicketsStatus;
  }

  setupTopUpItem() {
    this.logService.debug('Setting topup item', this.ticketItem);

    this.topUpPostItem = new TopUpTicketPostModel(
      this.ticketItem.BarcodeText,
      this.ticketItem.BarcodeACN,
      this.ticketItem.GameNumber,
      this.ticketItem.Area,
      this.ticketItem.Row,
      this.ticketItem.Seat,
      this.ticketItem.Balance,
      this.ticketItem.Stand,
      this.ticketItem.GameDate
    );
  }

  setSelectedTopUpAmount(topUpAmount: number) {
    this.logService.debug('Setting selected top up amount', topUpAmount);
    this.topUpAmountSelected = topUpAmount;
    this.topUpPostItem.amount = topUpAmount;
    if (topUpAmount !== null) {
      this.topupTicketStore.addOrUpdateTopUpTicket(this.topUpPostItem);
    }
  }

  revertToAccountBalance() {
    const statusTo = this.topUpTicketsStatus.UnloadedActive;
    const manageTicket = new ManageTicketStatusModel(
      this.ticketItem.GameNumber,
      this.ticketItem.BarcodeACN,
      this.ticketItem.BarcodeText,
      this.ticketItem.Area,
      this.ticketItem.Row,
      this.ticketItem.Seat,
      statusTo,
      this.ticketItem.Balance,
      this.ticketItem.GameDate
    );

    this.ticketsService.manageTicketStatus(manageTicket).subscribe(status => {
      this.ticketItem.Status = status;
      this.balanceService.addBalance(config.purseConfig.combined, this.ticketItem.Balance);
      this.ticketsService.getTicketsWithBalance(manageTicket.GameNumber).subscribe();
    });
  }
}
