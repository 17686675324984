import { Component, OnInit, Input } from '@angular/core';
import { JokesModel } from '@fgb/core';

@Component({
  selector: 'fgb-jokes-card',
  templateUrl: './jokes-card.component.html',
  styleUrls: ['./jokes-card.component.scss'],
})
export class JokesCardComponent implements OnInit {
  @Input() joke: JokesModel;
  flipped: boolean;
  constructor() {}

  ngOnInit() {}

  buttonClick() {
    this.flipped = !this.flipped;
  }
}
