import { Component, OnInit, Input } from '@angular/core';
import { Survey, AnswerDisplayType, SurveyAnswer } from '@fgb/core';

@Component({
  selector: 'fgb-survey-item-answer-completed-quiz',
  templateUrl: './survey-completed-quiz.component.html',
  styleUrls: ['./survey-completed-quiz.component.scss', '../../../survey.scss'],
})
export class SurveyCompletedQuizComponent implements OnInit {
  @Input() survey: Survey;
  selectedAnswers: SurveyAnswer[] = [];
  correctAnswer: SurveyAnswer;
  hasSelectedCorrect: boolean = false;
  pointsWon: number;
  answerDisplayType = AnswerDisplayType;

  constructor() {}

  ngOnInit() {
    this.selectedAnswers = this.survey.SurveyAnswers.filter((sa) => sa.UserSelected);
    this.hasSelectedCorrect = this.selectedAnswers.filter((a) => a.IsCorrectAnswer).length > 0;
    if (this.hasSelectedCorrect) {
      this.pointsWon = this.selectedAnswers[0].PointValue / 100;
    } else {
      this.correctAnswer = this.survey.SurveyAnswers.filter((sa) => sa.IsCorrectAnswer)[0];
    }
  }
}
