<ng-container *ngIf="status === scorePredictorStatus.ComingSoon">
  <div class="entry-window-countdown justify-content-center align-items-center d-flex w-100 text-black bg-grey px-3 text-center">
    <div class="font-size-12 score-predictor-footer-text" [innerHTML]="'score.predictor.footer.coming.soon.text' | translate | markdownTranslate"></div>
    <fgbcl-countdown
        [endDateTime]="survey.SeparateEntryWindow ? survey.EntryStartDate : survey.StartDate"
        [isUTC]="true"
        class="countdown font-size-18 font-weight-bold"
    ></fgbcl-countdown>
  </div>
</ng-container>

<!--Completion Section-->
<ng-container *ngIf="status === scorePredictorStatus.Answered">
  <div class="text-center w-100 d-flex">
    <fgb-score-predictor-completed
      [survey]="survey"
      [hasSelectedCorrect]="hasSelectedCorrect"
      [pointsWon]="pointsWon"
      [canEdit]="canEdit"
      (editAnswer)="editAnswer.emit()"
    ></fgb-score-predictor-completed>
  </div>
</ng-container>

<!--Submit Button-->
<ng-container *ngIf="status === scorePredictorStatus.EntryOpen">
  <div class="d-flex justify-content-center">
    <button class="btn btn-submit font-size-14 font-weight-bold" (click)="submitAnswer.emit()">{{ 'score.predictor.footer.submit.button' | translate }}</button>
  </div>
</ng-container>
