<div class="topUpTicketForm" [ngClass]="{cardLayout: cardLayout}">
  <ng-container *ngIf="!showTopUp">
    <button type="button" class="btn add-funds" (click)="toggle()">Add Funds</button>
  </ng-container>
  <ng-container *ngIf="showTopUp">
    <div class="topUpSelection" *ngIf="topUpAmounts$ | async as topUpAmounts">
      <select [(ngModel)]="selectOption" (change)="selectTopUpAmount()" class="form-control">
        <option *ngFor="let amount of topUpAmounts" [value]="amount">
          {{amount | FortressCurrencyPipe: 'USD'}}
        </option>
      </select>
      <span class="icon icon-cancel-circle" (click)="resetToUpAmount()"></span>
    </div>
  </ng-container>
</div>
