<div class="text-center px-4">
  <div class="d-flex justify-content-center">
    <img class="claimedBenefit" width="100" height="100" src="{{ benefit.ImageFileName | contentImage: '1' }}" />
  </div>
  <div class="d-flex justify-content-center mt-2">
    <h4>{{benefit.Name}}</h4>
  </div>
  <div class="d-flex justify-content-center">
    <p>{{benefit.Description}}</p>
  </div>
  <div class="d-flex justify-content-center">
    <button class="btn btn-danger mb-4" (click)="closeModal()">Close</button>
  </div>
</div>
