import { Component, OnInit } from '@angular/core';
import { config } from '@fgb/core';
import { Observable, Subscription } from 'rxjs';
import { BalanceQuery } from '@fgb/core';

@Component({
  selector: 'fgb-ecash-balance',
  templateUrl: './ecash-balance.component.html',
  styleUrls: ['./ecash-balance.component.scss'],
})
export class EcashBalanceComponent implements OnInit {
  combinedCard$: Observable<number | undefined>;
  subscription: Subscription = new Subscription();

  constructor(private balanceQuery: BalanceQuery) {}

  ngOnInit() {
    this.combinedCard$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
  }
}
