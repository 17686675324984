import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
