import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FantasyGame, FantasyPlayer } from '@fgb/core';

@Component({
  selector: 'fgb-fantasy-player-select-confirm',
  templateUrl: './fantasy-player-select-confirm.component.html',
  styleUrls: ['./fantasy-player-select-confirm.component.scss'],
})
export class FantasyPlayerSelectConfirmComponent implements OnInit {
  @Output() button = new EventEmitter<any>();
  @Input() game: FantasyGame;
  @Input() buttonState: 'player-link' | 'no-player-link' | 'make-selection' | 'confirm' | 'changing-player' = 'no-player-link';
  @Input() isGamePending: boolean = false;
  @Input() sameLine: boolean = false;
  @Input() selectedPlayer: FantasyPlayer;

  constructor() {}

  ngOnInit() {}

  buttonClick() {
    this.button.emit('pressed');
  }
}
