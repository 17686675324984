<div class="full-page bg-primary container-fluid w-100">
  <div class="row">
    <div class="col-12">
      <div class="panel rounded overflow-hidden p-0">
        <div class="row">
          <div class="col-lg-7 d-none d-lg-block login-banner"></div>
          <div class="user-login-panel col-lg-5 col-12 text-left bg-white">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="d-flex justify-content-center align-items-center mb-2">
                    <div><img src="assets/images/login-registration/fortress-login.svg" class="logo mt-3" /></div>
                  </div>
                  <div class="text panel-text font-size-14 text-center">
                    <p>Attempting to login</p>
                    <fgbcl-loading [size]="'small'"></fgbcl-loading>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
