import { Component, OnInit, Input } from '@angular/core';
import { Benefit, BenefitService } from '@fgb/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-benefit-claim-confirmation-modal',
  templateUrl: './benefit-claim-confirmation-modal.component.html',
  styleUrls: ['./benefit-claim-confirmation-modal.component.scss']
})
export class BenefitClaimConfirmationModalComponent implements OnInit {

  @Input() benefit: Benefit;

  constructor(private activeModal: NgbActiveModal, private benefitService: BenefitService) { }

  ngOnInit() {
  }

  closeModal(): void {
    this.activeModal.close();
  }

  claimBenefit(benefit: Benefit) {
    this.benefitService
      .claimBenefit(benefit)
      .toPromise()
      .finally(() => this.closeModal());
  }

}
