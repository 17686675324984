import { Component, OnInit } from '@angular/core';
import { FantasyHistory, FantasyHistoryQuery } from '@fgb/core';
import { Observable } from 'rxjs';
import * as _moment from 'moment';
import { map } from 'rxjs/operators';
const moment = _moment;

@Component({
  selector: 'fgb-fantasy-history-list',
  templateUrl: './fantasy-history-list.component.html',
  styleUrls: ['./fantasy-history-list.component.scss'],
})
export class FantasyHistoryListComponent implements OnInit {
  fantasyHistory$: Observable<FantasyHistory[]>;
  pendingPeriod: number = 0;

  constructor(private fantasyHistoryQuery: FantasyHistoryQuery) {}

  ngOnInit() {
    this.fantasyHistory$ = this.fantasyHistoryQuery
      .selectHistoryBeforeDate(moment.utc().subtract(this.pendingPeriod, 'hours'))
      .pipe(map((x) => (x = x.slice(0, 5))));
  }
}
