import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TopUpTicketPostModel, TopupTicketStore } from '@fgb/core';

@Component({
  selector: 'fgb-ticket-topup-checkout-list',
  templateUrl: './ticket-topup-checkout-list.component.html',
  styleUrls: ['./ticket-topup-checkout-list.component.scss']
})
export class TicketTopupCheckoutListComponent implements OnInit {
  topUpTickets$: Observable<TopUpTicketPostModel[]>;

  constructor(private topupTicketStore: TopupTicketStore) {
    this.topUpTickets$ = this.topupTicketStore.topUpTickets$;
  }

  ngOnInit() {}
}
