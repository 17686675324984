import { Component, OnInit } from '@angular/core';
import { TMOauthService } from '@fgb/core';


@Component({
  selector: 'fgb-tm-callback',
  templateUrl: './tm-callback.component.html',
  styleUrls: ['./tm-callback.component.scss']
})
export class TmCallbackComponent implements OnInit {

  constructor(private tmOauthService: TMOauthService) { }

  ngOnInit() {
    let index = window.location.href.indexOf('code');
    if (index != -1){
      this.tmOauthService.callBackToken(window.location.href.substring(index + 5));
    }
  }

}
