<google-map height="{{ mapHeight }}" width="100%" [zoom]="mapZoom" [center]="mapCenter" [options]="mapOptions">
  <map-marker
    #markerElem="mapMarker"
    *ngFor="let marker of customMarkers"
    [position]="marker.position"
    [label]="marker.label"
    [title]="marker.title"
    [options]="marker.options"
    (mapClick)="showMarkerDetails(markerElem, marker.departmentID, true)"
  >
  </map-marker>
  <map-info-window #infoWindow (closeclick)="emitSelectedDepartmentID()">
    <div class="container row py-2">
      <div class="col-3" *ngIf="mapInfo?.image">
        <img class="w-100" src="{{ mapInfo?.image | contentImage: '6' }}" alt="" />
      </div>
      <div [ngClass]="mapInfo?.image ? 'col-9' : 'col-12'">
        <div class="title font-weight-bold font-size-14 mb-1">
          {{ mapInfo?.title }}
        </div>
        <div class="text-info font-size-12 mb-2">
          {{ mapInfo?.address }}
        </div>
        <div class="font-size-12">
          {{ mapInfo?.description }}
        </div>
      </div>
    </div>
  </map-info-window>
</google-map>
