import { Component, OnInit, Input } from '@angular/core';
import { Card } from '@fgb/core';

@Component({
  selector: 'fgb-card-list-item',
  templateUrl: './card-list-item.component.html',
  styleUrls: ['./card-list-item.component.scss']
})
export class CardListItemComponent implements OnInit {
  @Input() card: Card;

  constructor() {}

  ngOnInit() {}
}
