<div class="table-format" *ngIf="displayAsTableItem; else card">
  <div class="col-lg-7">
    <div class="row p-2 ticket-item">
      <div class="ticket-item-barcode col w-40">
        {{ ticketItem.BarcodeText }}
      </div>
      <div class="ticket-item-section col w-30">
        {{ ticketItem.Stand }}
      </div>
      <div class="ticket-item-row col-1">
        {{ ticketItem.Row }}
      </div>
      <div class="ticket-item-seat col-1">
        {{ ticketItem.Seat }}
      </div>
      <div class="ticket-item-balance text-success col">
        {{ ticketItem.Balance | FortressCurrencyPipe: 'USD' }}
      </div>
    </div>
  </div>
  <div class="col-lg-2">
    <button class="btn revert" (click)="revertToAccountBalance()">Revert to account balance</button>
  </div>
  <div class="col-lg-3">
    <div class="row">
      <ng-container *ngIf="ticketItem.Status == topUpTicketsStatus.LoadedHasMoney; else blocked">
        <fgb-top-up-ticket (topUpAmountSelected)="setSelectedTopUpAmount($event)"></fgb-top-up-ticket>
      </ng-container>
      <ng-template #blocked>
        <div class="addfunds blocked">
          <p>Add Funds is disabled when payment is inactive</p>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #card>
  <div class="ticket-card">
    <div class="ticket-card-heading">
      <div class="seat">
        <span>Seat</span>
        {{ ticketItem.Seat }}
      </div>
      <div class="row">
        <span>Row</span>
        {{ ticketItem.Row }}
      </div>
      <div class="section">
        <span>Section</span>
        {{ ticketItem.Stand }}
      </div>
    </div>
    <div class="ticket-card-body loaded">
      <div class="card-info">
        <div class="ticket-barcode">
          <span>Ticket Barcode</span>
          {{ ticketItem.BarcodeText }}
        </div>
        <div class="ticket-balance">
          {{ ticketItem.Balance | FortressCurrencyPipe: 'USD' }}
        </div>
      </div>
      <div class="revert-balance">
        <button class="btn revert" (click)="revertToAccountBalance()">Revert to account balance</button>
      </div>
    </div>
    <div class="ticket-card-footer">
      <fgb-top-up-ticket (topUpAmountSelected)="setSelectedTopUpAmount($event)" [cardLayout]="true"></fgb-top-up-ticket>
    </div>
  </div>
</ng-template>
