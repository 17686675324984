import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'fgb-earn-page',
  templateUrl: './earn-page.component.html',
  styleUrls: ['./earn-page.component.scss'],
})
export class EarnPageComponent implements OnInit {
  deviceType: string;

  constructor(private deviceDetector: DeviceDetectorService) {}

  ngOnInit() {
    this.deviceType = this.deviceDetector.os.toUpperCase();
  }
}
