<div class="banner" role="banner">
  <h1>{{ 'offers.page.title' | translate }}</h1>
</div>

<div class="container mt-4 offers">
  <!--Featured Offers Section-->
  <ng-container *ngIf="featuredOffers$ | async as featuredOffers">
    <div class="row" *ngIf="!!featuredOffers.length">
      <div class="col-12">
        <h2 class="mt-0">{{ 'featured.offers.title' | translate }}</h2>
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let offer of featuredOffers">
            <ng-template carouselSlide [width]="301">
              <fgb-offers-list-item (navigateToOffer)="navigateToOffer($event)" [offer]="offer"></fgb-offers-list-item>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </ng-container>
</div>

<!--Featured Partners Section-->
<ng-container *ngIf="featuredPartners$ | async as featuredPartners">
  <div class="featured-partners bg-secondary mt-4" *ngIf="!!featuredPartners.length">
    <div class="container">
      <div class="row">
        <div class="col-12 offers">
          <h2 class="mt-0 text-white text-center">{{ 'featured.brands.title' | translate }}</h2>
          <owl-carousel-o [options]="customOptions2">
            <ng-container *ngFor="let partner of featuredPartners">
              <ng-template carouselSlide [width]="100">
                <fgb-partners-list-item (click)="navigateToFirstOfferFromAffiliate(partner)" [partner]="partner">
                </fgb-partners-list-item>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!--Offer Earn Points Tab-->
<fgb-offer-earn-tab></fgb-offer-earn-tab>

<!--All Offers-->
<fgb-offers-list (navigateToOffer)="navigateToOffer($event)" [isList]="true"></fgb-offers-list>
