<div class="d-flex justify-content-between mb-3">
  <ng-container *ngFor="let option of optionInfos; index as i">
    <div class="shadow rounded option-cards p-1">
      <p
        class="icon icon-info text-tertiary text-right mb-0 cursor-pointer"
        (click)="openMembershipOptionModal(productInfo, i)"
      ></p>
      <div class="text-center">
        <img alt="" class="option-card-img mb-2" [src]="option.Image" />
      </div>
      <div class="text-primary text-center options-carousel-text">
        <div class="font-weight-bold mb-0">{{ option.DisplayName }}</div>
        <div class="mb-0">{{ option.Age }}</div>
        <div class="font-weight-bold mb-0">{{ option.Price }}</div>
      </div>
    </div>
  </ng-container>
</div>

<!--info modal of options-->
<ng-template #productInfo let-modal>
  <div class="text-black bg-white">
    <ng-container *ngFor="let option of optionInfos; index as i">
      <ng-container *ngIf="i == optionInfoIndex">
        <div class="d-flex justify-content-between p-3">
          <div class="d-flex">
            <img alt="" class="option-card-img my-auto mr-2" [src]="option.Image" />
            <h5 class="my-auto font-weight-bold text-primary">{{ option.DisplayName }} {{ option.Age }} {{ option.Price }}</h5>
          </div>
          <div class="icon icon-cancel-circle cursor-pointer" (click)="modal.dismiss('close')"></div>
        </div>
        <p class="font-size-14 px-3 mb-4">
          {{ option.Description }}
        </p>
        <hr class="mx-3" />
        <div class="d-flex px-3">
          <img alt="" class="what-glove-img mr-2" src="assets/images/membership-options/what-gloves.png" />
          <p class="options-guide">
            Please note that a “right-handed” kit is for a child that throws right-handed, and a “left-handed” kit is for a child
            that throws left-handed. Free shipping on all domestic orders. Before upgrading your child’s membership please contact
            <span class="text-primary font-weight-bold">kidnation@redsox.com</span> to update your current shipping address.
          </p>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
