import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeyValuePair, MemberQuery, ProviderName, SQWADService } from '@fgb/core';
@Component({
  selector: 'fgb-provider-callback',
  templateUrl: './provider-callback.component.html',
  styleUrls: ['./provider-callback.component.scss'],
})
export class ProviderCallbackComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private memberQuery: MemberQuery,
    private sqwadService: SQWADService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const providerIdStr = this.activatedRoute.snapshot.paramMap.get('providerid');
    if (!providerIdStr) {
      return;
    }

    const providerId = +providerIdStr;

    if (providerId == ProviderName.SQWAD) {
      const externalRef = this.activatedRoute.snapshot.queryParamMap.get('userid');
      let win = this.activatedRoute.snapshot.queryParamMap.get('win')?.toLocaleLowerCase();

      const currentExternalRef = this.memberQuery.getUserContext().externalRef;

      if (!externalRef || externalRef != currentExternalRef) {
        this.sqwadRedirectToHomepage(false);
        return;
      }

      if (win != 'true') {
        win = 'false';
      }

      const options = [{ key: 'win', value: win }] as KeyValuePair[];
      this.sqwadService.callback(externalRef, options);

      if (win == 'true') {
        this.sqwadRedirectToHomepage(true);
        return;
      }

      this.sqwadRedirectToHomepage(false);
      return;
    }
  }

  sqwadRedirectToHomepage(win: boolean) {
    this.router.navigateByUrl('/?win=' + win);
  }
}
