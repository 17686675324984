<div class="banner" role="banner">
  <h1>eCASH</h1>
</div>

<!--nav ecash & renewal bonus transfer-->
<ul class="nav nav-secondary mixed-loyalty-nav mt-0 mb-3">
  <a class="nav-item btn" [routerLink]="['/ecash']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <div class="icon svg activity ml-2 my-0" [inlineSVG]="'assets/images/icons/money-bag.svg'"></div>
    <label>eCash</label>
  </a>
  <a
    class="nav-item btn"
    [routerLink]="['/ecash/bonustransfer']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <div class="icon icon-swap-horizontal ml-2 my-0"></div>
    <label>Bonus Transfer</label>
  </a>
</ul>
<!--EO: ecash & renewal bonus nav -->

<div class="container">
  <ng-container *ngIf="!isLoading">
    <div class="balance-bar w-100 shadow rounded row m-auto d-lg-flex d-none">
      <div class="bg-primary col-2 h-100 rounded-left text-center">
        <img alt="" class="club-logo mt-3" src="assets/images/logo.svg" draggable="false" />
      </div>
      <div class="col-2 h-100 text-center mt-3">
        <div class="text-left">
          <span class="font-weight-bold text-uppercase font-size-14">Bonus Credit</span><br />
          <span class="text-success font-weight-bold">{{ bonusCredit$ | async | FortressCurrencyPipe }}</span>
        </div>
      </div>
      <div class="col-8 h-100">
        <p class="font-size-12 mt-3">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias consequatur temporibus libero, at quisquam
          cupiditate perferendis repellendus exercitationem quis nihil accusantium quod? Officiis placeat, officia ullam facilis
          aliquid distinctio doloribus.
        </p>
      </div>
    </div>

    <div class="shadow rounded d-lg-none">
      <div class="row mx-auto">
        <div class="col-6 h-100 text-center bg-primary mobile-logo-bg shadow">
          <img alt="" class="club-logo my-3" src="assets/images/logo.svg" draggable="false" />
        </div>
        <div class="col-6 shadow">
          <div class="mt-2 pl-1">
            <span class="font-weight-bold text-uppercase font-size-14">Bonus Credit</span><br />
            <span class="text-success font-weight-bold">{{ bonusCredit$ | async | FortressCurrencyPipe }}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="font-size-12 mt-3 px-3">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias consequatur temporibus libero, at quisquam
            cupiditate perferendis repellendus exercitationem quis nihil accusantium quod? Officiis placeat, officia ullam facilis
            aliquid distinctio doloribus.
          </p>
        </div>
      </div>
    </div>

    <div class="text-center mt-4">
      <h4>Bonus Credit</h4>
      <div
        class="shadow rounded"
        [ngClass]="{
          'partner-container': showPartners,
          'non-partner-container': !showPartners
        }"
      >
        <p class="font-weight-bold font-size-14 pt-3">Who would you like to transfer Bonus Credit to?</p>
        <div class="btn-group btn-group-toggle font-size-14" data-toggle="buttons">
          <label
            class="btn toggle-btn cursor-pointer"
            (click)="showPartners = true"
            [ngClass]="{ 'btn-primary': showPartners, 'btn-secondary': !showPartners }"
          >
            <input
              type="radio"
              name="options"
              id="option1"
              autocomplete="off"
              attr.aria-label="{{ 'bonus.transfer.partner.screenreader' | translate }}"
              checked
            />
            {{ 'bonus.transfer.partner.screenreader' | translate }}
          </label>
          <label
            class="btn toggle-btn cursor-pointer"
            (click)="showPartners = false"
            [ngClass]="{ 'btn-secondary': showPartners, 'btn-primary': !showPartners }"
          >
            <input
              type="radio"
              name="options"
              id="option3"
              autocomplete="off"
              attr.aria-label="{{ 'bonus.transfer.nonpartner.screenreader' | translate }}"
            />
            {{ 'bonus.transfer.nonpartner.screenreader' | translate }}
          </label>
        </div>

        <div class="mt-4">
          <ng-container *ngIf="showPartners">
            <fgb-partner-bonus-transfer></fgb-partner-bonus-transfer>
          </ng-container>
          <ng-container *ngIf="!showPartners">
            <fgb-non-partner-bonus-transfer></fgb-non-partner-bonus-transfer>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <fgbcl-loading></fgbcl-loading>
  </ng-container>
</div>
