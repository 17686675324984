import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { BalanceService, config, TopUpTicketPostModel, TopupTicketService, TopupTicketStore } from '@fgb/core';

@Component({
  selector: 'fgb-ticket-topup-checkout',
  templateUrl: './ticket-topup-checkout.component.html',
  styleUrls: ['./ticket-topup-checkout.component.scss']
})
export class TicketTopupCheckoutComponent implements OnInit, OnDestroy {
  @Input() gameNumber: number;
  @Output() resetView = new EventEmitter<boolean>(false);
  topupTicketsSubscription: Subscription = new Subscription();
  submitTopUpSubscription: Subscription = new Subscription();

  topUpTickets: Array<TopUpTicketPostModel>;
  topUpBalance: number;

  constructor(
    private topupTicketsService: TopupTicketService,
    private topupTicketStore: TopupTicketStore,
    private modalService: NgbModal,
    private balanceService: BalanceService
  ) {
    this.topupTicketsSubscription = this.topupTicketStore.topUpTickets$.subscribe(tickets => {
      this.topUpTickets = tickets;
      this.topUpBalance = this.getTopUpBasketBalance();
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.topupTicketsSubscription) {
      this.topupTicketsSubscription.unsubscribe();
    }

    if (this.submitTopUpSubscription) {
      this.submitTopUpSubscription.unsubscribe();
    }
  }

  open(content: any) {
    this.modalService.open(content, { windowClass: 'topUpModal' });
  }

  submitTopUp() {
    this.modalService.dismissAll();
    this.submitTopUpSubscription = this.topupTicketsService.topUpTickets(this.topUpTickets)
      .subscribe(result => {
        this.balanceService.debitBalance(config.purseConfig.combined, this.topUpBalance);
        this.topupTicketStore.clearAllTopUpTickets();
        this.topupTicketsService.getTicketsWithBalance(this.gameNumber).subscribe();
      });
  }

  getTopUpBasketBalance(): number {
    return this.topUpTickets.map(t => t.amount).reduce((total, amount) => total + amount, 0);
  }

  isNaN(num: number) {
    return Number.isNaN(num);
  }
}
