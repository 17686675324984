import { Component, OnInit, Input } from '@angular/core';
import { TicketWithBalanceModel, TopUpTicketsStatus } from '@fgb/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'fgb-ticket-loaded-list',
  templateUrl: './ticket-loaded-list.component.html',
  styleUrls: ['../ticket-topup-list.scss']
})
export class TicketLoadedListComponent implements OnInit {
  @Input() loadedTickets$: Observable<TicketWithBalanceModel[]>;
  loadedHasMoneyTickets$: Observable<TicketWithBalanceModel[]>;

  constructor() { }

  ngOnInit() {
    this.loadedHasMoneyTickets$ = this.loadedTickets$.pipe(
      map(tickets => {
        return tickets.filter(ticket => ticket.Status === TopUpTicketsStatus.LoadedHasMoney);
      })
    );
  }
}
