import { Component, OnInit } from '@angular/core';
import {
  CardTokenQuery,
  CardToken,
  TransactionType,
  MemberQuery,
  MemberCard,
  MemberCardQuery,
  BalanceQuery,
  config,
} from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-ecash-page',
  templateUrl: './ecash-page.component.html',
  styleUrls: ['./ecash-page.component.scss'],
})
export class EcashPageComponent implements OnInit {
  defaultCardToken$: Observable<CardToken>;
  memberCard$: Observable<MemberCard | undefined>;
  combinedCard$: Observable<number | undefined>;

  constructor(
    private cardTokenQuery: CardTokenQuery,
    private memberQuery: MemberQuery,
    private memberCardQuery: MemberCardQuery,
    private balanceQuery: BalanceQuery
  ) {}

  transactionStartDate: Date;
  transactionEndDate: Date;
  transactionType: TransactionType;
  // expose the enum to the template
  transactionTypes = TransactionType;
  memberId: string;
  ngOnInit() {
    this.memberCard$ = this.memberCardQuery.selectCurrentCard();
    this.memberId = this.memberQuery.getUserContext().memberId;
    this.transactionType = TransactionType.ecash;
    this.transactionEndDate = new Date();
    this.transactionStartDate = new Date();
    this.transactionStartDate.setDate(new Date(this.transactionStartDate).getDate() - 1);
    this.defaultCardToken$ = this.cardTokenQuery.selectDefaultCard();
    this.combinedCard$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
  }

  setTransactionType(type: TransactionType) {
    this.transactionType = type;
  }
}
