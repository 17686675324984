import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { PredictorAnswerType, Survey } from '@fgb/core';

@Component({
  selector: 'fgb-score-predictor-completed-pending',
  templateUrl: './score-predictor-completed-pending.component.html',
  styleUrls: ['./score-predictor-completed-pending.component.scss'],
})
export class ScorePredictorCompletedPendingComponent implements OnInit, OnChanges {
  @Input() survey: Survey;
  @Input() canEdit: boolean = false;
  @Output() editAnswer = new EventEmitter<null>();

  homeAnswer: string | undefined;
  awayAnswer: string | undefined;

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    const home = this.survey.SurveyAnswers.find((x) => x.AnswerType === PredictorAnswerType.Home);
    if (home) {
      this.homeAnswer = home.AnswerValue;
    }

    const away = this.survey.SurveyAnswers.find((x) => x.AnswerType === PredictorAnswerType.Away);
    if (away) {
      this.awayAnswer = away.AnswerValue;
    }
  }
}
