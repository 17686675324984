<div class="table-format" *ngIf="displayAsTableItem; else card">
  <div class="col-lg-9">
    <div class="row p-2 ticket-item">
      <div class="ticket-item-status col-2">
        <label class="checkbox-switch d-block position-relative">
          <input type="checkbox" [checked]="isActive" (change)="changeStatus()" [(ngModel)]="isActive" />
          <div class="slider round"></div>
        </label>
        <div class="ticket-status-tag">
          <ng-container *ngIf="isActive == true; else inactive">
            <span>Active</span>
          </ng-container>
          <ng-template #inactive>
            <span>InActive</span>
          </ng-template>
        </div>
      </div>
      <div class="ticket-item-barcode col w-30">
        {{ ticketItem.BarcodeText }}
      </div>
      <div class="ticket-item-section col-3">
        {{ ticketItem.Stand }}
      </div>
      <div class="ticket-item-row col-1">
        {{ ticketItem.Row }}
      </div>
      <div class="ticket-item-seat col-1">
        {{ ticketItem.Seat }}
      </div>
      <div class="ticket-item-balance text-success col">
        {{ ticketItem.Balance | FortressCurrencyPipe: 'USD' }}
      </div>
    </div>
  </div>
  <div class="col-lg-3">
    <div class="row">
      <ng-container *ngIf="ticketItem.Status == topUpTicketsStatus.UnloadedActive; else blocked">
        <fgb-top-up-ticket (topUpAmountSelected)="setSelectedTopUpAmount($event)" [cardLayout]="false"></fgb-top-up-ticket>
      </ng-container>
      <ng-template #blocked>
        <div class="addfunds blocked">
          <p>Add Funds is disabled when payment is inactive</p>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #card>
  <div class="ticket-card">
    <div class="ticket-card-heading">
      <div class="seat">
        <span>Seat</span>
        {{ ticketItem.Seat }}
      </div>
      <div class="row">
        <span>Row</span>
        {{ ticketItem.Row }}
      </div>
      <div class="section">
        <span>Section</span>
        {{ ticketItem.Stand }}
      </div>
    </div>
    <div class="ticket-card-body">
      <div class="ticket-barcode">
        <span>Ticket Barcode</span>
        {{ ticketItem.BarcodeText }}
      </div>
      <div class="ticket-status">
        <label class="checkbox-switch d-block position-relative">
          <input type="checkbox" [checked]="isActive" (change)="changeStatus()" [(ngModel)]="isActive" />
          <div class="slider round"></div>
        </label>
        <div class="ticket-status-tag">
          <ng-container *ngIf="isActive == true; else inactive">
            <span>Active</span>
          </ng-container>
          <ng-template #inactive>
            <span>InActive</span>
          </ng-template>
        </div>
      </div>
      <div class="ticket-balance">
        {{ ticketItem.Balance | FortressCurrencyPipe: 'USD' }}
      </div>
    </div>
    <div class="ticket-card-footer" [ngClass]="{ blocked: ticketItem.Status != topUpTicketsStatus.UnloadedActive }">
      <ng-container *ngIf="ticketItem.Status == topUpTicketsStatus.UnloadedActive; else blocked">
        <fgb-top-up-ticket (topUpAmountSelected)="setSelectedTopUpAmount($event)" [cardLayout]="true"></fgb-top-up-ticket>
      </ng-container>
      <ng-template #blocked>
        <div class="addfunds blocked cardLayout">
          <p>Add Funds is disabled when payment is inactive</p>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
