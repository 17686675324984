import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BenefitHeaderComponent } from './components/benefit-header/benefit-header.component';
import { BenefitItemListComponent } from './components/benefit-item-list/benefit-item-list.component';
import { BenefitItemComponent } from './components/benefit-item/benefit-item.component';
import { BenefitClaimedListComponent } from './components/benefit-claimed-list/benefit-claimed-list.component';
import { BenefitInfoModalComponent } from './components/benefit-info-modal/benefit-info-modal.component';
import { BenefitClaimConfirmationModalComponent } from './components/benefit-claim-confirmation-modal/benefit-claim-confirmation-modal.component';
import { BenefitClaimedComponent } from './components/benefit-claimed/benefit-claimed.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    BenefitHeaderComponent,
    BenefitItemListComponent,
    BenefitItemComponent,
    BenefitClaimedListComponent,
    BenefitInfoModalComponent,
    BenefitClaimConfirmationModalComponent,
    BenefitClaimedComponent
  ],
  exports: [
    BenefitHeaderComponent,
    BenefitItemListComponent,
    BenefitItemComponent,
    BenefitClaimedListComponent,
    BenefitInfoModalComponent,
    BenefitClaimConfirmationModalComponent,
    BenefitClaimedComponent
  ],
  imports: [CommonModule, SharedModule, NgbModule]
})
export class BenefitModule {}
