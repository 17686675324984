import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Regex } from 'src/app/shared/utilities/regex';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  ExternalCashTransferService,
  VerifyEmailAddressRequest,
  ExternalCashTransferViewModel,
  BalanceQuery,
  config,
  ErrorStateService,
  MemberQuery,
} from '@fgb/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fgb-transfer-arena-cash',
  templateUrl: './transfer-arena-cash.component.html',
  styleUrls: ['./transfer-arena-cash.component.scss'],
})
export class TransferArenaCashComponent implements OnInit, OnDestroy {
  balance: number;
  submitted = false;
  loading = false;
  transferCashForm: FormGroup = this.formBuilder.group({
    emailAddress: ['', [Validators.required, Validators.pattern(Regex.email)]],
    amount: ['', [Validators.required, Validators.pattern(Regex.currency)]],
  });
  balanceSub: Subscription;
  memberSub: Subscription;
  currentEmail: string;

  @ViewChild('invalidFunds') invalidFundsRef: ElementRef;
  @ViewChild('transferFailed') transferFailedRef: ElementRef;
  @ViewChild('transferOwnEmail') transferOwnEmailRef: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private externalCashTransferService: ExternalCashTransferService,
    private router: Router,
    private modalService: NgbModal,
    private balanceQuery: BalanceQuery,
    private errorService: ErrorStateService,
    private memberQuery: MemberQuery
  ) {}

  ngOnInit() {
    this.balanceSub = this.balanceQuery.selectPurse(config.purseConfig.combined).subscribe((bal) => {
      this.balance = bal ?? 0;
    });

    this.memberSub = this.memberQuery.selectMemberDetails().subscribe((mem) => {
      if (mem) {
        this.currentEmail = mem.EmailAddress1;
      }
    });
  }

  ngOnDestroy() {
    if (this.balanceSub) {
      this.balanceSub.unsubscribe();
    }
    if (this.memberSub) {
      this.memberSub.unsubscribe();
    }
  }

  get f() {
    return this.transferCashForm.controls;
  }

  verifyEmail() {
    this.submitted = true;
    this.loading = true;
    if (!this.transferCashForm.valid) {
      this.loading = false;
      return;
    }

    if (this.balance === undefined) {
      this.errorService.clearErrors();
      this.errorService.addError('Balance is not available at this time');
      this.loading = false;
      return;
    }

    let verifyRequest = new VerifyEmailAddressRequest();
    verifyRequest.EmailAddress = this.transferCashForm.get('emailAddress')!.value;
    verifyRequest.Amount = this.transferCashForm.get('amount')!.value as number;

    if (this.currentEmail === verifyRequest.EmailAddress) {
      this.modalService
        .open(this.transferOwnEmailRef, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' })
        .result.then(
          (result) => {},
          (reason) => {}
        );
      this.loading = false;
      return;
    }

    if (this.balance < verifyRequest.Amount) {
      this.modalService
        .open(this.invalidFundsRef, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' })
        .result.then(
          (result) => {},
          (reason) => {}
        );
      this.loading = false;
      return;
    }

    this.externalCashTransferService
      .verifyEmailAddress(verifyRequest)
      .toPromise()
      .then((response) => {
        if (response === null) {
          this.modalService
            .open(this.transferFailedRef, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' })
            .result.then(
              (result) => {},
              (reason) => {}
            );
          this.loading = false;
        } else if (response.Accounts[0].MemberIDs.length > 1) {
          this.router.navigate(['arenacashmultipleaccounts']);
        } else {
          this.transferFunds(response);
        }
      });
  }

  private transferFunds(transfer: ExternalCashTransferViewModel) {
    this.externalCashTransferService
      .transferFunds(transfer)
      .toPromise()
      .then(() => {
        this.router.navigate(['arenacashtransfersuccess']);
      })
      .finally(() => (this.loading = false));
  }
}
