import { Component, OnInit } from '@angular/core';
import { MemberQuery, MemberDetails, LoggingService } from '@fgb/core';
import { Router } from '@angular/router';

@Component({
  selector: 'fgb-login-accounts-page',
  templateUrl: './login-accounts-page.component.html',
  styleUrls: ['./login-accounts-page.component.scss']
})
export class LoginAccountsPageComponent implements OnInit {
  availableAccounts: MemberDetails[];

  constructor(
    private memberQuery: MemberQuery,
    private logService: LoggingService,
    private router: Router
  ) {}

  ngOnInit() {
    this.availableAccounts = this.memberQuery.getAvailableAccounts() || [];
    if (this.availableAccounts.length === 0) {
      this.logService.warning('No accounts available to choose from - redirecting back to the login page...');
      this.router.navigate(['/login']);
    }
  }
}
