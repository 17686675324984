import { Component, OnInit, Input } from '@angular/core';
import { Survey, SurveyAnswer } from '@fgb/core';

@Component({
  selector: 'fgb-survey-completed-quiz-image-and-text',
  templateUrl: './survey-completed-quiz-image-and-text.component.html',
  styleUrls: ['./survey-completed-quiz-image-and-text.component.scss', '../../../../survey.scss']
})
export class SurveyCompletedQuizImageAndTextComponent implements OnInit {
  @Input() survey: Survey;
  @Input() selectedAnswers: SurveyAnswer[];
  @Input() correctAnswer: SurveyAnswer;
  @Input() hasSelectedCorrect: boolean;
  @Input() pointsWon: number;

  constructor() { }

  ngOnInit() {
  }

}
