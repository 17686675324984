import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'fgb-manage-tickets-page',
  templateUrl: './manage-tickets-page.component.html',
  styleUrls: ['./manage-tickets-page.component.scss']
})
export class ManageTicketsPageComponent implements OnInit {
  @Input() preselectedEventId = '';
  gameNumber$: Observable<string>;
  sub: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit() {
    this.gameNumber$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        of((params.get('id') || '0'))
      )
    );
  }

  setSelectedEvent(gameNumber: string) {
    this.router.navigate([`${this.location.path()}/${gameNumber}`]);
  }
}
