<div class="container">
  <div class="container success-box shadow mt-lg-5 mt-4">
    <h5 class="text-success text-center pt-3">purchase successful!</h5>
    <hr />
    <div class="row">
      <div class="col-lg-6 col-12 mb-3 nav-btn-col">
        <a class="btn btn-primary confirm-btn font-weight-bold font-size-14" [routerLink]="['/rewards/marketplace']"
          >Back to Rewards</a
        >
      </div>
      <div class="col-lg-6 col-12 text-right mb-3 nav-btn-col">
        <a class="btn btn-wallet confirm-btn font-weight-bold font-size-14" [routerLink]="['/rewards/wallet']">Rewards History</a>
      </div>
    </div>
  </div>
</div>
