import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'fgb-fantasy-player-select-page',
  templateUrl: './fantasy-player-select-page.component.html',
  styleUrls: ['./fantasy-player-select-page.component.scss']
})
export class FantasyPlayerSelectPageComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  id: number;
  ngOnInit() {
    this.id = +(this.route.snapshot.paramMap.get('id') || 0);
  }
}
