import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  EcashService,
  TopUpAmountTypeId,
  TicketWithBalanceModel,
  TopUpTicketsStatus,
  TopUpTicketPostModel,
  TopupTicketStore,
  TopupTicketService,

} from '@fgb/core';

@Component({
  selector: 'fgb-ticket-topup-list',
  templateUrl: './ticket-topup-list.component.html',
  styleUrls: ['./ticket-topup-list.component.scss']
})
export class TicketTopUpListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() gameNumber: string;
  tickets$: Observable<TicketWithBalanceModel[]>;
  ticketsUnloaded$: Observable<TicketWithBalanceModel[]>;
  ticketsLoaded$: Observable<TicketWithBalanceModel[]>;
  topUpPostTickets: TopUpTicketPostModel[];
  unloadedIds: number[] = [TopUpTicketsStatus.UnloadedActive, TopUpTicketsStatus.UnloadedBlocked];
  loadedIds: number[] = [TopUpTicketsStatus.LoadedHasMoney, TopUpTicketsStatus.LoadedNoMoney];
  getTicketsSubscription: Subscription = new Subscription();
  topUpAmountsSubscription: Subscription = new Subscription();


  constructor(
    private topupTicketsService: TopupTicketService,
    private ecashService: EcashService,
    private topupTicketStore: TopupTicketStore
    ) {
      this.ticketsUnloaded$ = this.topupTicketStore.tickets$
        .pipe(
          map(tickets => {
            return tickets.filter(ticket => this.unloadedIds.includes(ticket.Status));
          })
        );

      this.ticketsLoaded$ = this.topupTicketStore.tickets$
        .pipe(
          map(tickets => tickets.filter(ticket => this.loadedIds.includes(ticket.Status)))
        );
    }

  ngOnInit() {
    this.setTopUpAmounts();
  }

  ngOnChanges() {
    if (+this.gameNumber > 0) {
      this.getTickets();
    }
  }

  ngOnDestroy() {
    if (this.getTicketsSubscription) {
      this.getTicketsSubscription.unsubscribe();
    }

    if (this.topUpAmountsSubscription) {
      this.topUpAmountsSubscription.unsubscribe();
    }
  }

  public getTickets() {
    this.getTicketsSubscription = this.topupTicketsService.getTicketsWithBalance(+this.gameNumber).subscribe();
  }

  setTopUpAmounts() {
    this.topUpAmountsSubscription = this.ecashService.getTopUpAmounts(
      TopUpAmountTypeId.TopUpTicketAmount
    ).subscribe();
  }
}
