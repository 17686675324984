<ul class="nav nav-secondary mixed-loyalty-nav">
  <li
    class="nav-item btn-light"
    [routerLink]="['/managetickets/']"
    [routerLinkActive]="'active'"
  >
    <div class="icon icon-align-top"></div>
    <label>
      Top Up Ticket
    </label>
  </li>
  <li
    class="nav-item btn-light"
    [routerLink]="['/manageticketshistory']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <div class="icon icon-hierarchy-structure-6"></div>
    <label class="nav-link">History</label>
  </li>
</ul>
