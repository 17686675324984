import { Component, OnInit } from '@angular/core';
import { SurveyQuery } from '@fgb/core';
import { Survey } from '@fgb/core';
import { Observable } from 'rxjs';
import { SurveyType } from '@fgb/core';

@Component({
  selector: 'fgb-quiz-page',
  templateUrl: './quiz-page.component.html',
  styleUrls: ['./quiz-page.component.scss'],
})
export class QuizPageComponent implements OnInit {
  quizzes$: Observable<Survey[]>;

  constructor(private surveyQuery: SurveyQuery) {}

  ngOnInit() {
    this.quizzes$ = this.surveyQuery.selectRelevantSurveys(SurveyType.Trivia);
  }
}
