import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-membership-options-carousel',
  templateUrl: './membership-options-carousel.component.html',
  styleUrls: ['./membership-options-carousel.component.scss'],
})
export class MembershipOptionsCarouselComponent implements OnInit {
  optionInfoIndex: number;
  optionInfos = [
    {
      DisplayName: 'Rookie',
      Age: '(FREE)',
      Price: 'Mailed $5.95',
      Description:
        'Included in this membership is a Kid Nation lanyard and membership card. Rookie memberships can be picked up at the ballpark when you attend your first game at Fenway Park or mailed to you for a fee of $5.95. This membership includes a FREE ticket (with the purchase of an adult ticket) to a Red Sox home game during the 2021 season.',
      Image: 'assets/images/membership-options/option1.png',
      ReadMore: false,
    },
    {
      DisplayName: 'All-Star Kit',
      Age: '(Age 6 & Under)',
      Price: '$54.95',
      Description:
        'Included in this membership is an exclusive Kid Nation Franklin Sports navy youth backpack, Franklin Sports soft-tee ball, authentic Franklin Sports Baseball Glove (size 9.5”), lanyard, and membership card. RECOMMENDED AGE: 6 and under. \n Please note that a “right-handed” kit is for a child that throws right-handed, so the glove will go on their left hand. A “left-handed” kit is for a child that throws left-handed, so the glove will go on their right hand.',
      Image: 'assets/images/membership-options/option2.png',
      ReadMore: false,
    },
    {
      DisplayName: 'All-Star Kit',
      Age: '(Age 7 - 10)',
      Price: '$54.95',
      Description:
        'Included in this membership is an exclusive Kid Nation Franklin Sports heather gray backpack, Franklin sports baseball, authentic Franklin Sports Baseball Glove (size 11”), lanyard, and membership card. RECOMMENDED AGE: 7-10. \n Please note that a “right-handed” kit is for a child that throws right-handed, so the glove will go on their left hand. A “left-handed” kit is for a child that throws left-handed, so the glove will go on their right hand.',
      Image: 'assets/images/membership-options/option3.png',
      ReadMore: false,
    },
    {
      DisplayName: 'All-Star Kit',
      Age: '(Age 11 - 14)',
      Price: '$54.95',
      Description:
        'Included in this membership is an exclusive Kid Nation Franklin Sports heather gray backpack, Franklin sports baseball, authentic Franklin Sports Baseball Glove (size 12”), lanyard, and membership card. RECOMMENDED AGE: 11-14. \n Please note that a “right-handed” kit is for a child that throws right-handed, so the glove will go on their left hand. A “left-handed” kit is for a child that throws left-handed, so the glove will go on their right hand.',
      Image: 'assets/images/membership-options/option4.png',
      ReadMore: false,
    },
  ];
  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  openMembershipOptionModal(content: any, optionIndex: number) {
    this.optionInfoIndex = optionIndex;
    this.modalService.open(content, { centered: true });
  }
}
