import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, LoggingService, MemberQuery, LoginType } from '@fgb/core';

@Component({
  selector: 'fgb-v1-single-sign-on',
  templateUrl: './v1-single-sign-on.component.html',
  styleUrls: ['./v1-single-sign-on.component.scss'],
})
export class V1SingleSignOnComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private logService: LoggingService,
    private memberQuery: MemberQuery,
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit() {
    let sskey: string = this.getQueryString('sskey', '');
    let cc: string = this.getQueryString('cc', '');
    let returnUrl: string = this.getQueryString('returnUrl', '');

    this.logService.debug('V1 SSO login data', {
      sskey,
      cc,
    });

    this.logService.info('Attempting single sign on with login...');
    this.auth.ssoLoginV1(sskey, cc, returnUrl).subscribe(
      (result) => {
        this.logService.info('Successful V1 SSO login');
        this.logService.debug('V1 SSO login result data', result);

        if (this.memberQuery.getLoginType() != LoginType.Admin) {
          this.auth.checkApiStatus();
        }

        this.router.navigate([returnUrl]);
      },
      (error) => {
        if (error.status === 503) {
          this.logService.warning('503 [Service Unavailable] received; Maintenance mode is probably on', error);
          this.router.navigate(['maintenance']);
        } else {
          this.logService.error('Failed V1 SSO login', error);
          this.router.navigate(['login']);
        }
      }
    );
  }

  /** Get a query string parameter without case sensitivity. */
  private getQueryString(parameter: string, defaultValue: string): string {
    let matchedQueryStringParameter = this.route.snapshot.queryParamMap.keys.find(
      (param) => param.toUpperCase() === parameter.toUpperCase()
    );
    if (matchedQueryStringParameter === undefined) {
      return defaultValue;
    }
    return this.route.snapshot.queryParamMap.get(matchedQueryStringParameter) || defaultValue;
  }
}
