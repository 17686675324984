import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { MemberQuery } from '@fgb/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[inPopulation]',
})
export class InPopulationDirective {
  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private memberQuery: MemberQuery) {}

  @Input() set inPopulation(populations: string[]) {
    let memberPopulation = this.memberQuery.getPopulationName();
    this.viewContainer.clear();
    if (memberPopulation && populations.includes(memberPopulation)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
