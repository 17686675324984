

<h2>TOP UP AMOUNT -
  <span *ngIf="topUpBalance > 0; else zeroAmount">
    {{ topUpBalance | FortressCurrencyPipe: 'USD'}}
  </span>
  <ng-template #zeroAmount>
    {{0 | FortressCurrencyPipe: 'USD'}}
  </ng-template>
</h2>

<button type="button" class="btn checkout" (click)="open(checkoutModal)" [disabled]="isNaN(topUpBalance) || topUpBalance === 0">Add Funds</button>

<ng-template #checkoutModal let-modal>
  <div class="modal-body">
    Are you sure that you would like to update these funds?
    <fgb-ticket-topup-checkout-list></fgb-ticket-topup-checkout-list>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-success" (click)="submitTopUp()">Confirm</button>
  </div>
</ng-template>
