import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'fgb-lotto-wallet-details-page',
  templateUrl: './lotto-wallet-details-page.component.html',
  styleUrls: ['./lotto-wallet-details-page.component.scss']
})
export class LottoWalletDetailsPageComponent implements OnInit {
  id: number;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.id = +(this.route.snapshot.paramMap.get('id') || 0);
  }

}
