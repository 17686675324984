import { Component, OnInit } from '@angular/core';
import { JokesModel } from '@fgb/core';

@Component({
  selector: 'fgb-jokes-page',
  templateUrl: './jokes-page.component.html',
  styleUrls: ['./jokes-page.component.scss'],
})
export class JokesPageComponent implements OnInit {
  jokes: JokesModel[] = [
    new JokesModel(
      'assets/images/jokes/banners/banner-question-1.png',
      'assets/images/jokes/banners/banner-answer-1.png',
      'Which superhero is best at baseball?',
      'Batman',
      'assets/images/jokes/emojis/emoji-1.png'
    ),
    new JokesModel(
      'assets/images/jokes/banners/banner-question-2.png',
      'assets/images/jokes/banners/banner-answer-2.png',
      'Which superhero is best at baseball?',
      'Batman',
      'assets/images/jokes/emojis/emoji-2.png'
    ),
    new JokesModel(
      'assets/images/jokes/banners/banner-question-3.png',
      'assets/images/jokes/banners/banner-answer-3.png',
      'Which superhero is best at baseball?',
      'Batman',
      'assets/images/jokes/emojis/emoji-3.png'
    ),
    new JokesModel(
      'assets/images/jokes/banners/banner-question-1.png',
      'assets/images/jokes/banners/banner-answer-1.png',
      'Which superhero is best at baseball?',
      'Batman',
      'assets/images/jokes/emojis/emoji-1.png'
    ),
    new JokesModel(
      'assets/images/jokes/banners/banner-question-2.png',
      'assets/images/jokes/banners/banner-answer-2.png',
      'Which superhero is best at baseball?',
      'Batman',
      'assets/images/jokes/emojis/emoji-2.png'
    ),
    new JokesModel(
      'assets/images/jokes/banners/banner-question-3.png',
      'assets/images/jokes/banners/banner-answer-3.png',
      'Which superhero is best at baseball?',
      'Batman',
      'assets/images/jokes/emojis/emoji-3.png'
    ),
  ];
  constructor() {}

  ngOnInit() {}
}
