import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fgb-benefit-header',
  templateUrl: './benefit-header.component.html',
  styleUrls: ['./benefit-header.component.scss']
})
export class BenefitHeaderComponent implements OnInit {

  @Input() claimLimit: number;
  @Input() numberClaimed: number;

  constructor() { }

  ngOnInit() {
  }

}
