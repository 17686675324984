import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Benefit } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BenefitInfoModalComponent } from '../benefit-info-modal/benefit-info-modal.component';

@Component({
  selector: 'fgb-benefit-claimed-list',
  templateUrl: './benefit-claimed-list.component.html',
  styleUrls: ['./benefit-claimed-list.component.scss'],
})
export class BenefitClaimedListComponent implements OnInit, OnChanges {
  @Input() benefitsClaimed: Benefit[];
  @Input() claimLimit: number;

  unclaimedBenefits: number[];

  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  ngOnChanges(): void {
    this.unclaimedBenefits = this.getRemainingClaimsArray();
  }

  getRemainingClaimsArray(): number[] {
    if (this.claimLimit) {
      const remainingClaimNumber = this.claimLimit - this.benefitsClaimed.length;
      return new Array(remainingClaimNumber);
    }

    return new Array(0);
  }

  openModal(benefit: Benefit): void {
    const modal = this.modalService.open(BenefitInfoModalComponent);
    modal.componentInstance.benefit = benefit;
  }
}
