import { Component, OnDestroy, OnInit } from '@angular/core';
import { BalanceQuery, config, Partner, PartnerQuery, RenewalBonusService } from '@fgb/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'fgb-renewal-bonus-transfer-page',
  templateUrl: './renewal-bonus-transfer-page.component.html',
  styleUrls: ['./renewal-bonus-transfer-page.component.scss'],
})
export class RenewalBonusTransferPageComponent implements OnInit, OnDestroy {
  showPartners: boolean = true;
  bonusCredit$: Observable<number | undefined>;
  renewalSub: Subscription;
  partnerSub: Subscription;
  partners: Partner[];
  isLoading: boolean = true;

  constructor(
    private balanceQuery: BalanceQuery,
    private renewalService: RenewalBonusService,
    private partnerQuery: PartnerQuery
  ) {}

  ngOnInit() {
    this.renewalSub = this.renewalService.getRenewalBonusData().subscribe({
      next: () => {
        this.isLoading = false;
        this.bonusCredit$ = this.balanceQuery.selectPurse(config.purseConfig.renewalGift);
      },
      error: (err) => {
        this.isLoading = false;
      },
    });

    this.partnerSub = this.partnerQuery.selectAll().subscribe((partners) => {
      this.partners = partners;
    });
  }

  ngOnDestroy() {
    if (this.renewalSub) {
      this.renewalSub.unsubscribe();
    }
    if (this.partnerSub) {
      this.partnerSub.unsubscribe();
    }
  }
}
