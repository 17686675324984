import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CompanyOfferModel, CompanyOfferQuery } from '@fgb/core';
import { Observable } from 'rxjs';

import { tap } from 'rxjs/operators';
import { OffersMapComponent } from '../offers-map/offers-map.component';

@Component({
  selector: 'fgb-offers-list',
  templateUrl: './offers-list.component.html',
  styleUrls: ['./offers-list.component.scss'],
})
export class OffersListComponent implements OnInit {
  @Input() isList: boolean;
  @Input() pageSize = 9;
  @Input() pageNumber = 1;
  @Output() navigateToOffer = new EventEmitter<number>();
  @ViewChild(OffersMapComponent, { static: false }) map: OffersMapComponent;

  offersList$: Observable<CompanyOfferModel[]>;
  filteredOffers: CompanyOfferModel[];
  allShown: boolean;
  view: 'map' | 'grid' = 'grid';
  selectedDepartmentID: number | undefined;
  selectedOfferID: number | undefined;

  constructor(private companyOfferQuery: CompanyOfferQuery) {}

  ngOnInit() {
    this.showFirstPage();
  }

  showFirstPage() {
    this.allShown = false;
    this.offersList$ = this.companyOfferQuery.selectAllOffers().pipe(tap((offers) => this.updateFilteredOffers(offers, false)));
  }

  showAll() {
    this.allShown = true;
    this.offersList$ = this.companyOfferQuery.selectAllOffers().pipe(tap((offers) => this.updateFilteredOffers(offers, false)));
  }

  updateFilteredOffers(offers: CompanyOfferModel[], updateMap: boolean) {
    this.allShown ? (this.filteredOffers = offers) : (this.filteredOffers = offers.slice(0, this.pageSize));
    this.selectedDepartmentID = undefined;
    if (this.map && updateMap) {
      this.map.updateMarkers(offers);
      this.map.centerMap(offers[0]);
    }
  }

  navigatedToOffer(offerId: number) {
    this.navigateToOffer.emit(offerId);
  }

  selectOffer(offer: CompanyOfferModel): void {
    this.selectedOfferID = offer.OfferId;
    this.map.selectMarker(offer);
  }
}
