import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PopUpNotificationResponseType } from '@fgb/core';

@Component({
  selector: 'fgb-popup-action-confirm',
  templateUrl: './popup-action-confirm.component.html',
  styleUrls: ['./popup-action-confirm.component.scss']
})
export class PopupActionConfirmComponent implements OnInit {

  @Output() popupResponse = new EventEmitter<PopUpNotificationResponseType>();
  constructor() { }

  ngOnInit() {
  }

  emitResponse(res:PopUpNotificationResponseType): void {
    this.popupResponse.emit(res);
  }
}
