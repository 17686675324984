import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FantasyGamePanelComponent } from './components/fantasy-game-panel/fantasy-game-panel.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FantasyPlayerSelectComponent } from './components/fantasy-player-select/fantasy-player-select.component';
import { RouterModule } from '@angular/router';
import { FantasyHistoryListComponent } from './components/fantasy-history-list/fantasy-history-list.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FantasyHistoryListItemComponent } from './components/fantasy-history-list-item/fantasy-history-list-item.component';
import { FantasyPlayerSelectConfirmComponent } from './components/fantasy-player-select-confirm/fantasy-player-select-confirm.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { FantasyLeaderboardComponent } from './components/fantasy-leaderboard/fantasy-leaderboard.component';
import { FantasyGamePanelNextGameComponent } from './components/fantasy-game-panel-next-game/fantasy-game-panel-next-game.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CountdownModule } from '@fgb/portal-component-library/src/lib/shared/countdown';

@NgModule({
  declarations: [
    FantasyGamePanelComponent,
    FantasyPlayerSelectComponent,
    FantasyHistoryListComponent,
    FantasyHistoryListItemComponent,
    FantasyPlayerSelectConfirmComponent,
    FantasyLeaderboardComponent,
    FantasyGamePanelNextGameComponent,
  ],
  exports: [
    FantasyGamePanelComponent,
    FantasyPlayerSelectComponent,
    FantasyHistoryListComponent,
    FantasyGamePanelNextGameComponent,
    FantasyLeaderboardComponent,
  ],
  imports: [
    RouterModule,
    SharedModule,
    CommonModule,
    CarouselModule,
    CountdownModule,
    InlineSVGModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: function HttpLoaderFactory(http: HttpClient) {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
  ],
})
export class FantasyModule {}
