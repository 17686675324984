<div class="ticket-header">
  <h2>Tickets</h2>
  <p>
    All tickets will have access to your main account balance by default. Click Add Funds to add specific amount to each ticket.
  </p>
</div>

<fgb-ticket-unloaded-list [unloadedTickets$]="ticketsUnloaded$"></fgb-ticket-unloaded-list>
<h2 class="loaded">Loaded tickets</h2>
<fgb-ticket-loaded-list [loadedTickets$]="ticketsLoaded$"></fgb-ticket-loaded-list>

