import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Cart, CartQuery, CartService } from '@fgb/core';

@Component({
  selector: 'fgb-cart-item-subscriptions',
  templateUrl: './cart-item-subscriptions.component.html',
  styleUrls: ['./cart-item-subscriptions.component.scss'],
})
export class CartItemSubscriptionsComponent implements OnInit {
  items$: Observable<Cart[]>;

  constructor(private cartQuery: CartQuery, private cartService: CartService) {}

  ngOnInit() {
    this.items$ = this.cartQuery.selectAllSubscriptionCartData();
  }

  remove(cartItem: Cart) {
    this.cartService.remove(cartItem.id);
  }
}
