import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, of, timer } from 'rxjs';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { tap, concatMap, repeat } from 'rxjs/operators';

@Component({
  selector: 'fgb-navigation-indicator',
  templateUrl: './navigation-indicator.component.html',
  styleUrls: ['./navigation-indicator.component.scss']
})
export class NavigationIndicatorComponent implements OnInit, OnDestroy {
  navigationInProgress: boolean = false;
  navigationBarWidthPercentage: number = 0;
  private navigationSubscription: Subscription;
  private timerSubscription: Subscription;

  constructor(private router: Router) {}

  ngOnInit() {
    this.navigationSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this._startNavigation();
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this._completeNavigation();
      }
    });
  }

  ngOnDestroy() {
    this._cancelSubscription(this.timerSubscription);
    this._cancelSubscription(this.navigationSubscription);
  }

  /** Unsubscribe from the given subscription if it exists. */
  private _cancelSubscription(subscription: Subscription): void {
    if (subscription) {
      subscription.unsubscribe();
    }
  }

  /** Set the navigation bar state to the completed state. */
  private _startNavigation(): void {
    this._cancelSubscription(this.timerSubscription);

    this.navigationBarWidthPercentage = Math.round(20 + Math.random() * 10);
    this.navigationInProgress = true;

    this.timerSubscription = of(null)
      .pipe(
        concatMap(() => timer(500 + (Math.random() * 600))),
        tap(() => {
          // Increment the width slowly towards 95%...
          const remainingWidth: number = 95 - this.navigationBarWidthPercentage;
          const factor: number = 0.1 + Math.random() / 4; // from 0.1 to 0.35
          this.navigationBarWidthPercentage += Math.round(remainingWidth * factor);

          // Make sure the navigation width does not exceed 95% to show at least some small jump to 100% on completion
          this.navigationBarWidthPercentage = Math.min(Math.max(this.navigationBarWidthPercentage, 0), 95);
        }),
        repeat()
      )
      .subscribe();
  }

  /** Set the navigation bar state to the completed state. */
  private _completeNavigation(): void {
    this._cancelSubscription(this.timerSubscription);

    this.navigationBarWidthPercentage = 100;
    this.navigationInProgress = false;

    setTimeout(() => {
      if (!this.navigationInProgress) {
        this.navigationBarWidthPercentage = 0;
      }
    }, 300);
  }
}
