import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-leaderboard-page',
  templateUrl: './leaderboard-page.component.html',
  styleUrls: ['./leaderboard-page.component.scss']
})
export class LeaderboardPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
