import { Component, OnInit } from '@angular/core';
import { AuctionBidQuery, AuctionBidDetails } from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-auction-bids-page',
  templateUrl: './auction-bids-page.component.html',
  styleUrls: ['./auction-bids-page.component.scss']
})
export class AuctionBidsPageComponent implements OnInit {
  constructor(private auctionBidsQuery: AuctionBidQuery) {}
  auctionBids$: Observable<AuctionBidDetails[]>;
  ngOnInit() {
    this.auctionBids$ = this.auctionBidsQuery.selectBids();
  }
}
