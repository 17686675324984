<ng-container [ngSwitch]="survey.AnswerDisplayType">
  <div *ngSwitchCase="answerDisplayType.ImageAndText">
    <fgb-survey-completed-quiz-image-and-text
      [survey]="survey"
      [selectedAnswers]="selectedAnswers"
      [correctAnswer]="correctAnswer"
      [hasSelectedCorrect]="hasSelectedCorrect"
      [pointsWon]="pointsWon"
    ></fgb-survey-completed-quiz-image-and-text>
  </div>
  <div *ngSwitchCase="answerDisplayType.Text">
    <fgb-survey-completed-text
      [survey]="survey"
      [selectedAnswers]="selectedAnswers"
      [correctAnswer]="correctAnswer"
      [hasSelectedCorrect]="hasSelectedCorrect"
      [pointsWon]="pointsWon"
    ></fgb-survey-completed-text>
  </div>
  <div *ngSwitchCase="answerDisplayType.Image">
    <fgb-survey-completed-quiz-image
      [survey]="survey"
      [selectedAnswers]="selectedAnswers"
      [correctAnswer]="correctAnswer"
      [hasSelectedCorrect]="hasSelectedCorrect"
      [pointsWon]="pointsWon"
    ></fgb-survey-completed-quiz-image>
  </div>
</ng-container>
