<div class="card shadow border-0 mb-3" *ngIf="game">
  <div class="card-body px-0 pb-0">
    <div class="game overflow-hidden rounded">
      <div class="teams w-100 d-flex flex-nowrap bg-white position-relative p-3">
        <div class="team-logo d-flex align-items-center w-100 p-0 home-team">
          <img alt="Home Team" src="{{ getTeamLogoUrl(game.HomeTeamId) }}" draggable="false" />
        </div>
        <div class="versus font-weight-bold">V</div>
        <div class="team-logo align-items-center w-100 p-0 away-team">
          <img alt="Away Team" src="{{ getTeamLogoUrl(game.AwayTeamId) }}" draggable="false" />
        </div>
      </div>
      <div class="game-date-box bg-primary text-white font-size-16 font-weight-bold">
        {{ game.Date | date: 'MMMM d | h:mm a' | uppercase }}
      </div>
    </div>
  </div>
</div>
