import { Component, OnInit, Input } from '@angular/core';
import { Game, config } from '@fgb/core';

@Component({
  selector: 'fgb-game-card',
  templateUrl: './game-card.component.html',
  styleUrls: ['./game-card.component.scss']
})
export class GameCardComponent implements OnInit {
  @Input() game: Game;

  constructor() {
  }

  ngOnInit() {
  }

  getTeamLogoUrl(teamId: number) {
    return `${config.contentUrl}/image/teams/${teamId}__1_1_1033.png`;
  }
}
