<div *ngIf="activeUnloadedTickets$ | async as activeUnloadedTickets">
  <ng-container *ngIf="blockedUnloadedTickets$ | async as blockedUnloadedTickets">
    <ng-container *ngIf="activeUnloadedTickets.length || blockedUnloadedTickets?.length; else noTickets">
      <!-- TABLE FORMAT -->
      <div class="table-tickets">
        <div class="row header">
          <div class="col-lg-9">
            <div class="unloaded-ticket-header p-2">
              <div class="row">
                <div class="unloaded-ticket-status col-2">
                  Payment Status
                </div>
                <div class="unloaded-ticket-barcode col w-30">
                  Ticket Barcode
                </div>
                <div class="unloaded-ticket-section col-3">
                  Section
                </div>
                <div class="unloaded-ticket-row col-1">
                  Row
                </div>
                <div class="unloaded-ticket-seat col-1">
                  Seat
                </div>
                <div class="unloaded-ticket-balance col">
                  Balance
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- Table Ticket -->
          <div class="table-ticket">
            <div *ngFor="let ticket of activeUnloadedTickets" class="ticket-item-container">
              <fgb-ticket-details-unloaded [ticketItem]="ticket" [displayAsTableItem]="true"></fgb-ticket-details-unloaded>
            </div>
          </div>
          <!-- Card Ticket -->
          <div *ngFor="let ticket of activeUnloadedTickets" class="col-md-6 card-ticket">
            <fgb-ticket-details-unloaded [ticketItem]="ticket" [displayAsTableItem]="false"></fgb-ticket-details-unloaded>
          </div>
        </div>
        <hr *ngIf="blockedUnloadedTickets?.length">
        <div class="row">
          <div class="table-ticket">
            <div *ngFor="let ticket of blockedUnloadedTickets" class="ticket-item-container">
              <fgb-ticket-details-unloaded [ticketItem]="ticket" [displayAsTableItem]="true"></fgb-ticket-details-unloaded>
            </div>
          </div>
          <div *ngFor="let ticket of blockedUnloadedTickets" class="col-md-6 card-ticket">
            <fgb-ticket-details-unloaded [ticketItem]="ticket" [displayAsTableItem]="false"></fgb-ticket-details-unloaded>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #noTickets>
      <div class="row">
        <div class="col-lg-12 none-found">
          No tickets found
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
