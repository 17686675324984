import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardSelectBannerComponent } from './components/banners/card-select-banner/card-select-banner.component';
import { UserModule } from '../user/user.module';

@NgModule({
  declarations: [
    CardSelectBannerComponent,
  ],
  imports: [
    CommonModule,
    UserModule,
  ],
  exports: [
    CardSelectBannerComponent,
  ]
})
export class DisplayModule { }
