import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-refer-a-friend-page',
  templateUrl: './refer-a-friend-page.component.html',
  styleUrls: ['./refer-a-friend-page.component.scss']
})
export class ReferAFriendPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
