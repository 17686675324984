import { Component, OnInit } from '@angular/core';
import { CardLinkingQuery, CardLinkingService, CardLinkOffersQuery } from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-card-link-affiliates-page',
  templateUrl: './card-link-affiliates-page.component.html',
  styleUrls: ['./card-link-affiliates-page.component.scss'],
})
export class CardLinkAffiliatesPageComponent implements OnInit {
  activatedOffersCount$: Observable<number>;
  linkedCardsCount$: Observable<number>;

  constructor(
    private cardLinkOffersQuery: CardLinkOffersQuery,
    private cardLinkingServices: CardLinkingService,
    private cardLinkingQuery: CardLinkingQuery
  ) {}

  ngOnInit(): void {
    this.cardLinkingServices.fetchFidelLinkedCard().toPromise();
    this.activatedOffersCount$ = this.cardLinkOffersQuery.selectActivatedOffersCount();
    this.linkedCardsCount$ = this.cardLinkingQuery.selectCount();
  }
}
