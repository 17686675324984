<form [formGroup]="referAFriendForm" (ngSubmit)="sendReferral(referAFriendForm.value)">
  <div class="row">
    <div class="col-4">
      <label>
        Friend Name
      </label>
      <input type="text" class="form-control" formControlName="friendName" />
      <div *ngIf="f.friendName.invalid && f.friendEmail.touched" class="error-text">
        <div *ngIf="f.friendName.errors.required">
          Name is required.
        </div>
      </div>
    </div>
    <div class="col-4">
      <label>
        Friend Email
      </label>
      <input type="text" class="form-control" formControlName="friendEmail" />
      <div *ngIf="f.friendEmail.invalid && f.friendEmail.touched" class="error-text">
        <div *ngIf="f.friendEmail.errors.required">
          Email is required.
        </div>
        <div *ngIf="f.friendEmail.errors.email">
          Email is in the wrong format
        </div>
      </div>
    </div>
    <div class="col-4">
      <label>
        Friend Phone
      </label>
      <input type="text" class="form-control" formControlName="friendPhone" />
      <div *ngIf="f.friendPhone.invalid && f.friendPhone.touched" class="error-text">
        <div *ngIf="f.friendPhone.errors.required">
          Phone number is required.
        </div>
      </div>
    </div>
    <div class="col-12">
      <label>
        Reason
      </label>
      <textarea class="form-control" formControlName="reason"></textarea>
      <div *ngIf="f.reason.invalid && f.reason.touched" class="error-text">
        <div *ngIf="f.reason.errors.required">
          Reason is required.
        </div>
      </div>
    </div>
    <div class="col-12">
      <button type="submit" class="btn btn-primary mt-2" [disabled]="referAFriendForm.invalid || loading">Send</button>
    </div>
  </div>
</form>
