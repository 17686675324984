import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: 'ecash-failure-page.component.html',
  styleUrls: ['ecash-failure-page.component.scss'],
  selector: 'fgb-ecash-failure',
})
export class EcashFailurePageComponent implements OnInit {
  ngOnInit() {}
}
