import { Component, OnInit } from '@angular/core';
import { VoucherService, VoucherRedeemModel, BalanceService, config, ErrorStateService } from '@fgb/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'fgb-vouchers',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.scss'],
})
export class VouchersComponent implements OnInit {
  voucherCode: string;
  redemptionResult: string;
  disableButton: boolean;
  sucessfulRedemption: boolean;
  private messageTimeout: NodeJS.Timeout;

  constructor(private voucherService: VoucherService, private balanceService: BalanceService, private errorService: ErrorStateService, private translate: TranslateService) {}

  ngOnInit() {  }

  redeemVoucher() {
    if(this.messageTimeout) {
      clearTimeout(this.messageTimeout);
    }
    this.clearMessages();
    this.sucessfulRedemption = false;
    this.disableButton = true;

    if (!this.voucherCode) {
      this.redemptionResult = this.translate.instant('voucher.code.empty.text');
      this.disableButton = false;
      this.clearMessagesAfter(10000);
      return;
    }

    let voucher = new VoucherRedeemModel();
    voucher.VoucherCode = this.voucherCode;
    this.voucherService
      .redeemVoucher(voucher)
      .toPromise()
      .then((item) => {
        this.balanceService.fetchBalance(config.purseConfig.virtualLoyalty).pipe(take(1)).subscribe();
        this.redemptionResult = this.translate.instant("voucher.process.code");
        this.sucessfulRedemption = true;
      })
      .finally(() => {
        this.disableButton = false;
        this.clearMessagesAfter(10000);
      });
  }

  private clearMessagesAfter(timeAfter: number) {
    this.messageTimeout = setTimeout(() => {
      this.clearMessages();
    }, timeAfter);
  }

  private clearMessages() {
    this.redemptionResult = '';
    this.errorService.clearErrors();
  }
}
