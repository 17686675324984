<ng-container *ngSubscribe="linkedCardsCount$ as linkedCardsCount">
  <ng-container *ngIf="linkedCardsCount <= 0">
    <div class="container">
      <button class="btn affiliate-link-your-card" [routerLink]="['/linkcard']">
        <span class="icon material-icons">credit_card</span>{{ 'affiliate-link-card-button-title' | translate }}
      </button>
    </div>
  </ng-container>
</ng-container>

<ul class="nav nav-secondary">
  <a
    class="nav-item card-linking-offer-nav"
    [routerLink]="['/affiliate/alloffers']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <p>
      <span class="icon material-icons">style</span>
      {{ 'affiliate-tab-all-offers-text' | translate }}
    </p>
  </a>
  <a
    class="nav-item card-linking-offer-nav"
    [routerLink]="['/affiliate/myoffers']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <p>
      <span class="icon material-icons">credit_score</span>{{ 'affiliate-tab-my-offers-text' | translate }}
      <ng-container *ngIf="activatedOffersCount$ | async as activatedCount"
        ><span class="font-weight-normal">({{ activatedCount }})</span></ng-container
      >
    </p>
  </a>
</ul>

<router-outlet></router-outlet>
