import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'fgb-streamable-player',
  templateUrl: './fgb-streamable-player.component.html',
  styleUrls: ['./fgb-streamable-player.component.scss']
})
export class FgbStreamablePlayerComponent implements OnInit {

  @Input() streamKey: string;
  safeUrl : SafeResourceUrl;
  
  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.streamKey);
  }
}
