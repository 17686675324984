<div class="container">
  <!--header-->
  <div class="container h-100 shadow rounded mt-lg-4 mt-3">
    <div class="row">
      <div class="col-lg-6 col-12 subscription-header-banner"></div>
      <div class="col-lg-6 col-12">
        <h3 class="text-primary mt-2 font-size-22">
          <ng-container *ngIf="maxSubs > 0; else elseNoUpgrades">
            <span>You have</span> {{ activeSubs }}/{{ maxSubs }}
            <span>Digital Products</span>
          </ng-container>
          <ng-template #elseNoUpgrades>
            <span>No digital products available</span>
          </ng-template>
        </h3>
        <p class="font-size-14 font-weight-bold mb-0">NEW WAYS TO EARN POINTS!</p>
        <p class="font-size-12 text-info mb-lg-0">
          Note: Accumulation of points for new subscriptions begins the following game if a game is already underway.
        </p>
      </div>
    </div>
  </div>
  <!--membership options-->
  <div *ngIf="membershipPortalProducts.length && !foundMembership; else elseRenewals">
    <h3 class="mt-4 font-size-22">memberships</h3>
    <div class="row">
      <ng-container *ngFor="let membProduct of membershipPortalProducts">
        <div class="col-lg-6 col-12">
          <fgb-subscriptions-list-item [portalProduct]="membProduct"></fgb-subscriptions-list-item>
        </div>
      </ng-container>
    </div>
  </div>
  <!--renewals of memberships-->
  <ng-template #elseRenewals>
    <div *ngIf="foundMembership">
      <h3 class="mt-4 font-size-22">memberships</h3>
      <div class="row">
        <ng-container *ngFor="let product of purchasedProducts">
          <div class="col-lg-6 col-12" *ngIf="product.ProductType == 1">
            <fgb-subscriptions-list-item-renewals [subscribedProduct]="product"></fgb-subscriptions-list-item-renewals>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <!--subscription options-->
  <div *ngIf="subscriptionPortalProducts.length || foundSubscription">
    <h3 class="mt-4 font-size-22">Digital Products</h3>
    <div class="row">
      <!-- active portal products which are not purchased-->
      <ng-container *ngIf="notPurchasedActiveSubs.length > 0">
        <ng-container *ngFor="let subProduct of notPurchasedActiveSubs">
          <div class="col-lg-6 col-12">
            <fgb-subscriptions-list-item [portalProduct]="subProduct"></fgb-subscriptions-list-item>
          </div>
        </ng-container>
      </ng-container>
      <!-- portal products which have been purchased -->
      <ng-container *ngIf="purchasedProducts.length > 0">
        <ng-container *ngFor="let product of purchasedProducts">
          <div class="col-lg-6 col-12" *ngIf="product.ProductType == 3">
            <fgb-subscriptions-list-item-subscribed [subscribedProduct]="product"></fgb-subscriptions-list-item-subscribed>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <!--membership and subscription cart modal-->
  <div class="footer-sticky">
    <div class="footer-card my-3" *ngIf="items$ | async as items">
      <div class="card-body px-0" *ngIf="items.length">
        <div class="w-100 p-3 card shadow">
          <div class="d-flex justify-content-between">
            <h3 class="text-primary font-size-16 pl-2">your basket</h3>
            <h3 class="text-primary font-size-14 cancel-button-subs pr-lg-3 pr-2 cursor-pointer" (click)="clearSubsBasket()">
              Cancel
            </h3>
          </div>
          <div class="container mt-3">
            <div class="row">
              <div *ngFor="let item of items" class="col-lg-2 col-4">
                <div
                  class="sub-item rounded text-center mb-4"
                  [ngClass]="{
                    'bg-primary': item.productData.ProductType == 1,
                    'bg-secondary': item.productData.ProductType == 3
                  }"
                >
                  <img class="portal-product-img my-2" src="{{ item.productData.PrimaryImage | contentImage: '6' }}" />
                </div>
                <div
                  class="rounded-circle bg-white text-danger remove-product text-center shadow font-weight-bold border-0 cursor-pointer"
                  (click)="remove(item)"
                >
                  x
                </div>
              </div>
            </div>
            <hr />
            <div class="row mb-3">
              <div class="col-lg-9 col-6 text-lg-right font-size-14 py-2">
                <span class="font-weight-bold">Total Amount: </span
                ><span *ngIf="totalCost$ | async as totalCost" class="text-success">
                  {{ totalCost / 100 | FortressCurrencyPipe }}</span
                >
              </div>
              <div class="col-lg-3 col-6 text-right">
                <a [routerLink]="['/subscriptions/checkout']" routerLinkActive="router-link-active" class="rounded">
                  <button class="btn btn-success font-weight-bold w-100 checkout-btn font-size-14">Checkout</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--No new products or active products-->
  <ng-container *ngIf="allProducts == 0">
    <div>
      <img class="no-subs-image d-flex mx-auto" src="assets/images/placeholders/no-subscriptions.svg" />
      <h5 class="text-primary text-center mt-3 mb-0 font-weight-bold text-uppercase">SUBSCRIPTIONS</h5>
      <p class="text-info text-center font-size-14">Currently Unavailable</p>
    </div>
  </ng-container>
</div>
