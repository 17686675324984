import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-offer-earn-tab',
  templateUrl: './offer-earn-tab.component.html',
  styleUrls: ['./offer-earn-tab.component.scss'],
})
export class OfferEarnTabComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
