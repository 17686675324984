import { Component, OnInit } from '@angular/core';
import { MarketplaceItem, MarketplaceQuery, MarketplaceService } from '@fgb/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'fgb-marketplace-terms-page',
  templateUrl: './marketplace-terms-page.component.html',
  styleUrls: ['./marketplace-terms-page.component.scss'],
})
export class MarketplaceTermsPageComponent implements OnInit {
  marketplaceItem: MarketplaceItem | undefined;
  constructor(
    private marketplaceQuery: MarketplaceQuery,
    private marketplaceService: MarketplaceService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    let id = +(this.route.snapshot.paramMap.get('id') || 0);

    this.marketplaceService
      .fetchMarketplaceItemById(id)
      .toPromise()
      .then(() => {
        this.marketplaceItem = this.marketplaceQuery.getEntity(id);
      });
  }
}
