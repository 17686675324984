import { Component, OnInit, Input } from '@angular/core';
import { Benefit, BenefitClaimStatus, BenefitQuery } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BenefitInfoModalComponent } from '../benefit-info-modal/benefit-info-modal.component';
import { BenefitClaimConfirmationModalComponent } from '../benefit-claim-confirmation-modal/benefit-claim-confirmation-modal.component';

@Component({
  selector: 'fgb-benefit-item',
  templateUrl: './benefit-item.component.html',
  styleUrls: ['./benefit-item.component.scss'],
})
export class BenefitItemComponent implements OnInit {
  @Input() benefit: Benefit;
  @Input() disabled = false;

  isClaimed: boolean;
  inactive: boolean = false;
  BenefitClaimStatus = BenefitClaimStatus;
  outOfStock: boolean;

  statusText: string;

  constructor(private modalService: NgbModal, private benefitQuery: BenefitQuery) {}

  ngOnInit() {
    if (!this.benefitQuery.isActiveBenefit(this.benefit) && this.benefit.ShowWhenNotActive) {
      this.inactive = true;
    }

    this.isClaimed = this.isBenefitClaimed(this.benefit);

    this.outOfStock = this.benefit.Status === BenefitClaimStatus.OutOfStock;

    if (this.isClaimed) {
      this.statusText = 'Claimed';
    } else if (this.inactive) {
      this.statusText = 'Not active';
    } else if (this.outOfStock) {
      this.statusText = 'Out of stock';
    }
  }

  openReadMoreModal(benefit: Benefit): void {
    const modal = this.modalService.open(BenefitInfoModalComponent);
    modal.componentInstance.benefit = benefit;
  }

  openClaimModal(benefit: Benefit): void {
    const modal = this.modalService.open(BenefitClaimConfirmationModalComponent);
    modal.componentInstance.benefit = benefit;
  }

  isBenefitClaimed(benefit: Benefit): boolean {
    const claimedStatuses = [BenefitClaimStatus.Processed, BenefitClaimStatus.Unprocessed];
    return claimedStatuses.some((b) => b === benefit.Status);
  }
}
