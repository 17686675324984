<div *ngIf="loadedTickets$ | async as loadedTickets">
  <ng-container *ngIf="loadedTickets.length > 0; else noTickets">
    <div class="table-tickets">
      <div class="row header">
        <div class="col-lg-7">
          <div class="loaded-ticket-header p-2">
            <div class="row">
              <div class="loaded-ticket-barcode col w-40">
                Ticket Barcode
              </div>
              <div class="loaded-ticket-section col w-30">
                Section
              </div>
              <div class="loaded-ticket-row col-1">
                Row
              </div>
              <div class="loaded-ticket-seat col-1">
                Seat
              </div>
              <div class="loaded-ticket-balance col-1">
                Balance
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="loadedHasMoneyTickets$ | async as loadedHasMoneyTickets">
        <!-- Table Ticket -->
        <div class="table-ticket">
          <div *ngFor="let ticket of loadedHasMoneyTickets" class="ticket-item-container">
            <fgb-ticket-details-loaded [ticketItem]="ticket" [displayAsTableItem]="true"></fgb-ticket-details-loaded>
          </div>
        </div>
        <!-- Card Ticket -->
        <div *ngFor="let ticket of loadedHasMoneyTickets" class="col-md-6 card-ticket">
          <fgb-ticket-details-loaded [ticketItem]="ticket" [displayAsTableItem]="false"></fgb-ticket-details-loaded>
        </div>
      </div>
    </div>
  </ng-container>


  <ng-template #noTickets>
    <div class="row">
      <div class="col-lg-12 none-found">
        No tickets have been loaded
      </div>
    </div>
  </ng-template>
</div>
