import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { PortalProduct, PortalProductsQuery, PortalProductSubscription, Cart, CartQuery, CartService } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'fgb-subscriptions-list-item-renewals',
  templateUrl: './subscriptions-list-item-renewals.component.html',
  styleUrls: ['./subscriptions-list-item-renewals.component.scss'],
})
export class SubscriptionsListItemRenewalsComponent implements OnInit, OnDestroy {
  @Input() subscribedProduct: PortalProductSubscription;
  items$: Observable<Cart[]>;
  currentLcid: string | null = '';
  noticePeriod: Date;
  currentDate: Date;
  withinRenewal: boolean = false;
  renewalProduct: PortalProduct;
  currentSubscribedProduct: PortalProductSubscription;
  itemSub: Subscription;

  constructor(
    private cartQuery: CartQuery,
    private portalProductsQuery: PortalProductsQuery,
    private cartService: CartService,
    private modalService: NgbModal
  ) {
    this.currentLcid = localStorage.getItem('lcid');
  }

  ngOnInit() {
    this.items$ = this.cartQuery.selectAllSubscriptionCartData();
    this.updateStatus();
  }

  ngOnDestroy() {
    if (this.itemSub) {
      this.itemSub.unsubscribe();
    }
  }

  updateStatus() {
    if (this.subscribedProduct.ProductType === 1) {
      this.currentSubscribedProduct = this.subscribedProduct;
      this.noticePeriod = new Date(this.currentSubscribedProduct.NextPaymentDueDate);
      this.noticePeriod.setDate(this.noticePeriod.getDate() - 30);
      this.currentDate = new Date();

      if (this.currentDate > this.noticePeriod) {
        this.withinRenewal = true;
        this.getRenewalProduct();
      }
    }
  }

  // get renewal product
  getRenewalProduct() {
    let renewalPortalProducts: PortalProduct[];
    renewalPortalProducts = this.portalProductsQuery.getAllProductsByProductType(8, undefined);
    for (let product of renewalPortalProducts) {
      // will add condition here to compare attribute ID with current product, once attributes added to response
      this.renewalProduct = product;
    }
  }

  // add renewal product to cart
  addRenewalProductToCart() {
    if (!this.checkBasketForRenewal()) {
      this.cartService.addPortalProductToCart(this.renewalProduct);
    }
  }

  // checks if product type 8 renewal portal product exists within basket
  checkBasketForRenewal(): boolean {
    let itemsInCart: Cart[] = [];
    this.itemSub = this.items$.subscribe((data) => {
      itemsInCart = data;
    });
    for (let product of itemsInCart) {
      if (
        product.productData.ProductType === 8 &&
        (product.productData as PortalProduct).ProductId === this.renewalProduct.ProductId
      ) {
        return true;
      }
    }
    return false;
  }

  // info button of subscribed membership
  openProductDescription(content: any) {
    this.modalService.open(content, { centered: true });
  }
}
