import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import {
  CardToken,
  TicketWithBalanceModel,
  TopUpTicketPostModel,
  TopupTicketStore,
  TopupTicketService,
  ManageTicketStatusModel,
  TopUpTicketsStatus
 } from '@fgb/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fgb-ticket-details-unloaded',
  templateUrl: './ticket-details-unloaded.component.html',
  styleUrls: ['../ticket-details.scss']
})
export class TicketDetailsUnloadedComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ticketItem: TicketWithBalanceModel;
  @Input() displayAsTableItem: boolean;
  topUpPostItem: TopUpTicketPostModel;
  topUpAmountSelected: number;
  activeCard: CardToken;
  isActive: boolean;

  manageTicketSub: Subscription;

  constructor(
    private topupTicketStore: TopupTicketStore,
    private ticketsService: TopupTicketService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.manageTicketSub) {
      this.manageTicketSub.unsubscribe();
    }
  }

  ngOnChanges() {
    this.setupTopUpItem();
    if (this.ticketItem.Status === TopUpTicketsStatus.UnloadedActive) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }

  get topUpTicketsStatus() {return TopUpTicketsStatus; }

  setSelectedTopUpAmount(topUpAmount: number) {
    this.topUpAmountSelected = topUpAmount;
    this.topUpPostItem.amount = topUpAmount;
    if (topUpAmount !== undefined) {
      this.topupTicketStore.addOrUpdateTopUpTicket(this.topUpPostItem);
    } else {
      this.topupTicketStore.removeTopUpTicket(this.topUpPostItem.barcodeText);
    }
  }

  setupTopUpItem() {
    this.topUpPostItem = new TopUpTicketPostModel(
      this.ticketItem.BarcodeText,
      this.ticketItem.BarcodeACN,
      this.ticketItem.GameNumber,
      this.ticketItem.Area,
      this.ticketItem.Row,
      this.ticketItem.Seat,
      this.ticketItem.Balance,
      this.ticketItem.Stand,
      this.ticketItem.GameDate
    );
  }

  changeStatus() {
    const statusTo = ((this.isActive) ? this.topUpTicketsStatus.UnloadedActive : this.topUpTicketsStatus.UnloadedBlocked);
    const manageTicket = new ManageTicketStatusModel(
      this.ticketItem.GameNumber,
      this.ticketItem.BarcodeACN,
      this.ticketItem.BarcodeText,
      this.ticketItem.Area,
      this.ticketItem.Row,
      this.ticketItem.Seat,
      statusTo,
      this.ticketItem.Balance,
      this.ticketItem.GameDate
    );

    this.manageTicketSub = this.ticketsService.manageTicketStatus(manageTicket)
      .subscribe(status => {
        this.ticketItem.Status = status;
        this.topupTicketStore.addOrUpdateTicket(this.ticketItem);
        if (this.ticketItem.Status === this.topUpTicketsStatus.UnloadedBlocked) {
          this.topupTicketStore.removeTopUpTicket(this.ticketItem.BarcodeText);
        }
      });
  }
}
