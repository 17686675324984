import { Component, OnInit } from '@angular/core';
import { CardLinkingService, CardLinkOfferOptions, CardLinkOfferType } from '@fgb/core';

@Component({
  selector: 'fgb-card-linking-success-page',
  templateUrl: './card-linking-success-page.component.html',
  styleUrls: ['./card-linking-success-page.component.scss'],
})
export class CardLinkingSuccessPageComponent implements OnInit {
  cardLinkOfferOptionsRecommendedOffers: CardLinkOfferOptions = {
    offerType: CardLinkOfferType.OffersYouMayLike,
    limit: 4,
    showMoreButton: false,
    showMoreIncrement: 2,
  };

  constructor(private cardLinkingServices: CardLinkingService) {}

  ngOnInit(): void {
    this.cardLinkingServices.fetchFidelLinkedCard().toPromise();
  }
}
