<form [formGroup]="renewalTransferForm" *ngIf="!isLoading">
  <ng-container *ngIf="currentBalance > 0; else noBonusCredit">
    <p class="font-weight-bold font-size-14 pt-2">Please type the account number of your recipient</p>
    <div class="form-group">
      <label for="AccountNumber"> Account Number </label>
      <input class="form-control mx-auto bg-muted" name="AccountNumber" type="text" formControlName="TargetMember" required />
    </div>
  </ng-container>

  <hr class="my-4" />

  <div
    [ngClass]="{
      'disabled-number-input': currentBalance <= 0 || !currentBalance
    }"
  >
    <p class="font-weight-bold font-size-12">Please select the amount of Bonus Credit you <br />want to transfer.</p>
    <div class="number-input-container mx-auto">
      <fgbcl-number-input
        [isActive]="true"
        [minValue]="minTransfer"
        [maxValue]="currentBalance"
        [incrementValue]="1"
        [minMessage]="'$1 is the minimum'"
        [maxMessage]="'You do not have enough'"
        [(amount)]="transferValue"
      >
      </fgbcl-number-input>
    </div>

    <div class="my-3">
      <button class="btn btn-muted btn-bonus-value border mx-2 p-0" (click)="transferValue = 5">$5</button>
      <button class="btn btn-muted btn-bonus-value border mx-2 p-0" (click)="transferValue = 10">$10</button>
      <button class="btn btn-muted btn-bonus-value border mx-2 p-0" (click)="transferValue = 15">$15</button>
      <button class="btn btn-muted btn-bonus-value border mx-2 p-0" (click)="transferValue = 20">$20</button>
    </div>

    <button
      class="btn btn-primary send-btn mb-3"
      confirmModal
      [confirmContent]="confirmBonusTranser"
      (confirm)="transferBonusCredit(transferValue)"
      [disabled]="
        renewalTransferForm.controls.TargetMember.invalid || transferValue > currentBalance || transferValue < minTransfer
      "
    >
      Send
    </button>
  </div>

  <ng-template #confirmBonusTranser>
    <p class="font-weight-bold text-center">
      Are you sure you want to transfer
      <span class="text-success">{{ transferValue | FortressCurrencyPipe }} Bonus Credit</span> to <br />Acc. Number:
      <span class="text-danger">{{ renewalTransferForm.controls.TargetMember.value }}</span
      >?
    </p>
  </ng-template>

  <ng-template #noBonusCredit>
    <p class="font-weight-bold font-size-14">You currently have no bonus credit to transfer!</p>
  </ng-template>
</form>

<ng-container *ngIf="isLoading">
  <fgbcl-loading></fgbcl-loading>
</ng-container>

<!--modal content after transfer has been attempted-->
<ng-template #successTransfer let-modal>
  <div class="text-center transfer-status-modal">
    <p class="font-weight-bold text-center text-success my-4">Successfully Processed!</p>
    <button class="btn btn-success font-weight-bold mt-4" (click)="modal.dismiss('close')">Close</button>
  </div>
</ng-template>
<ng-template #failedTransfer let-modal>
  <div class="text-center transfer-status-modal">
    <p class="font-weight-bold text-center text-danger my-4">Unsuccessful Transfer!</p>
    <button class="btn btn-danger font-weight-bold mt-4" (click)="modal.dismiss('close')">Close</button>
  </div>
</ng-template>
