import { Component, Input, OnInit } from '@angular/core';
import { CompanyOfferModel } from '@fgb/core';

@Component({
  selector: 'fgb-partners-list-item',
  templateUrl: './partners-list-item.component.html',
  styleUrls: ['./partners-list-item.component.scss'],
})
export class PartnersListItemComponent implements OnInit {
  @Input() partner: CompanyOfferModel;

  constructor() {}

  ngOnInit() {}
}
