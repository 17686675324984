import { Component, OnInit } from '@angular/core';
import { config, BalanceQuery, MemberQuery, MemberDetails } from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-transfer-arena-cash-page',
  templateUrl: './transfer-arena-cash-page.component.html',
  styleUrls: ['./transfer-arena-cash-page.component.scss'],
})
export class TransferArenaCashPageComponent implements OnInit {
  combinedCard$: Observable<number | undefined>;
  memberDetails$: Observable<MemberDetails | undefined>;

  constructor(private balanceQuery: BalanceQuery, public memberQuery: MemberQuery) {}

  ngOnInit() {
    this.combinedCard$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
  }
}
