import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnswerDisplayType, Survey } from '@fgb/core';

@Component({
  selector: 'fgb-score-predictor-completed',
  templateUrl: './score-predictor-completed.component.html',
  styleUrls: ['./score-predictor-completed.component.scss'],
})
export class ScorePredictorCompletedComponent implements OnInit {
  @Input() survey: Survey;
  @Input() hasSelectedCorrect: boolean = false;
  @Input() pointsWon: number;
  @Input() canEdit: boolean;
  @Output() editAnswer = new EventEmitter<null>();
  answerDisplayType = AnswerDisplayType;

  constructor() {}

  ngOnInit() {}
}
