import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ExternalCashTransferQuery, ExternalCashTransferViewModel } from '@fgb/core';
import { Router } from '@angular/router';

@Component({
  selector: 'fgb-transfer-arena-cash-success-page',
  templateUrl: './transfer-arena-cash-success-page.component.html',
  styleUrls: ['./transfer-arena-cash-success-page.component.scss'],
})
export class TransferArenaCashSuccessPageComponent implements OnInit {
  transferAccounts$: Observable<ExternalCashTransferViewModel | undefined>;

  constructor(private externalCashTransferQuery: ExternalCashTransferQuery, private router: Router) {}

  ngOnInit() {
    if (this.externalCashTransferQuery.hasEntity()) {
      this.transferAccounts$ = this.externalCashTransferQuery.selectTransferAccounts();
    } else {
      this.router.navigate(['arenacashtransfer']);
    }
  }
}
