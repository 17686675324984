import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { map, filter, tap } from 'rxjs/operators';

import * as _moment from 'moment';
const moment = _moment;

@Component({
  selector: 'fgb-countdown-watch',
  templateUrl: './countdown-watch.component.html',
  styleUrls: ['./countdown-watch.component.scss'],
})
export class CountdownWatchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() endDateTime: string;

  /** The text to display in the countdown upon expiry. */
  @Input() completeText: string = 'Ended';

  /** Emitted upon countdown expiry. */
  @Output() complete = new EventEmitter<any>();

  days: number;
  hours: number;
  minutes: number;

  daysRemaining: number;
  completed = false;

  timerSub = new Subscription();

  private endDate: _moment.Moment;

  constructor() {}
  ngOnDestroy(): void {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.setTimer(this.endDateTime);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.endDateTime) {
      this.setTimer(changes.endDateTime.currentValue);
    }
  }

  /**
   * Sets the countdown timer relative to the supplied endDateTime.
   * @param endDateTime The time that the countdown should end as a UTC date.
   */
  setTimer(endDateTime: string): void {
    // The endDateTime param is UTC, so parse it as such
    this.endDate = moment.utc(endDateTime);

    if (this.endDate < moment.utc()) {
      this.completed = true;
      this.complete.emit(this.completeText);
    } else {
      this.completed = false;
      this.timerSub = timer(0, 1000)
        .pipe(
          // convert interval into remaining time in seconds
          map(() => {
            let utcNow = moment.utc();
            return Math.floor(this.endDate.diff(utcNow, 'seconds'));
          }),
          // stop emission after complete
          filter(() => !this.completed),
          // emit on complete
          tap((t) => {
            if (t <= 0) {
              this.completed = true;
              this.complete.emit(this.completeText);
            } else {
              this.days = Math.floor(t / 86400);
              this.hours = Math.floor((t - this.days * 86400) / 3600);
              this.minutes = Math.floor((t - (this.days * 86400 + this.hours * 3600)) / 60);
            }
          })
        )
        .subscribe();
    }
  }
}
