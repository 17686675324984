import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyOfferModel, CompanyOfferService, ClubCategoryTag } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { FilterByOption } from '@fgb/portal-component-library/src/lib/shared/filter-by';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'fgb-offers-toolbar',
  templateUrl: './offers-toolbar.component.html',
  styleUrls: ['./offers-toolbar.component.scss'],
})
export class OffersToolbarComponent implements OnInit {
  view: 'grid' | 'map' = 'grid';
  screenType = ScreenType;
  @Input() offers: CompanyOfferModel[];
  @Output() viewChange = new EventEmitter<'grid' | 'map'>();
  @Output() filteredArray = new EventEmitter<any[]>();

  filterOptions: FilterByOption;
  searchResults: CompanyOfferModel[];
  filterResults: CompanyOfferModel[];

  constructor(private companyOfferService: CompanyOfferService) {}

  ngOnInit(): void {
    this.searchResults = this.offers;
    this.filterResults = this.offers;
    this.companyOfferService
      .fetchOfferCategories()
      .pipe(
        tap((categories: ClubCategoryTag[]) => {
          this.filterOptions = {
            propertyToFilter: 'CategoryTags',
            subPropertyToFilter: 'CategoryName',
            options: categories.map((category) => category.CategoryName),
          };
        })
      )
      .subscribe();
  }

  /** Emits the view selected according to the tab clicked upon
   * @param selectedView may be either grid or map view
   */
  changeView(selectedView: 'grid' | 'map'): void {
    this.view = selectedView;
    this.viewChange.emit(selectedView);
  }

  /** Filters the filtered results received from the filterBy component
   * @param newArray array of company offers
   */
  filterArray(newArray: CompanyOfferModel[]): void {
    this.filterResults = newArray;
    this.emitCombinedArray();
  }

  /** Filters the searched results received from the SortBy component
   * @param newArray array of company offers
   */
  searchResult(newArray: CompanyOfferModel[]): void {
    this.searchResults = newArray;
    this.emitCombinedArray();
  }

  /** Emits array intersection of searched and filtered results */
  emitCombinedArray(): void {
    const combinedResults = this.filterResults.filter((value) => this.searchResults.indexOf(value) != -1);
    this.filteredArray.emit(combinedResults);
  }
}
