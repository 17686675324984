import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-auctions-page',
  templateUrl: './auctions-page.component.html',
  styleUrls: ['./auctions-page.component.scss']
})
export class AuctionsPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
