import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-points-management-page',
  templateUrl: './points-management-page.component.html',
  styleUrls: ['./points-management-page.component.scss'],
})
export class PointsManagementPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
