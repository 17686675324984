import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { EcashService, TopUpAmountTypeId } from '@fgb/core';
import { tap } from 'rxjs/operators';

@Component({
  templateUrl: 'ecash-success-page.component.html',
  styleUrls: ['ecash-success-page.component.scss'],
  selector: 'fgb-ecash-success',
})
export class EcashSuccessPageComponent implements OnInit {
  amounts$: Observable<number[]>;
  amount: number;
  autoTopUp: boolean = false;
  constructor(private ecashService: EcashService, private route: ActivatedRoute) {}
  ngOnInit() {
    this.amounts$ = this.ecashService.getTopUpAmounts(TopUpAmountTypeId.TopUpAmount).pipe(
      tap((amounts) => {
        if (amounts.length > 0) {
          this.amount = amounts[0];
        }
      })
    );

    if (this.route.snapshot.queryParamMap.get('autotopup')) {
      this.autoTopUp = true;
    }
  }
}
