<div class="banner" role="banner">
  <h1>{{ 'jokes.page.title' | translate }}</h1>
</div>

<fgb-game-nav></fgb-game-nav>

<div class="container">
  <div class="row">
    <div class="col-12 col-lg-4" *ngFor="let joke of jokes">
      <fgb-jokes-card [joke]="joke"></fgb-jokes-card>
    </div>
  </div>
</div>
