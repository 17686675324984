<div class="jokes-card">
  <div class="jokes-body h-100 w-100 position-relative" [ngClass]="{ flipped: flipped }">
    <div class="front rounded overflow-hidden shadow bg-white w-100 h-100 position-absolute">
      <img alt="" class="joke-banner w-100" src="{{ joke.questionBanner }}" />
      <div class="text mt-3 font-size-16 font-weight-bold line-height-18 text-center px-3">
        {{ joke.question }}
      </div>
      <div class="d-flex justify-content-center">
        <button
          class="flip bg-primary text-white font-weight-bold text-center rounded border-0 ml-auto mr-auto position-absolute"
          (click)="buttonClick()"
          attr.aria-label="{{ 'jokes.show.answer.screenreader' | translate }}"
        >
          ?
        </button>
      </div>
    </div>
    <div class="back rounded overflow-hidden shadow bg-primary w-100 h-100 position-absolute">
      <img alt="" class="joke-banner w-100" src="{{ joke.answerBanner }}" />
      <div class="text mt-3 font-size-16 font-weight-bold line-height-18 text-white text-center px-3">{{ joke.answer }}</div>
      <div class="emoji d-flex justify-content-center mb-2"><img alt="" src="{{ joke.answerEmoji }}" /></div>
      <div class="d-flex justify-content-center">
        <button
          class="flip answer bg-white text-primary font-weight-bold text-center rounded border-0 ml-auto mr-auto icon icon-reload position-absolute"
          (click)="buttonClick()"
          attr.aria-label="{{ 'jokes.show.question.screenreader' | translate }}"
        ></button>
      </div>
    </div>
  </div>
</div>
