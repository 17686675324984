import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Inject, Injectable } from "@angular/core";
import { DateOptions, DATE_OPTIONS } from '@fgb/core';

@Injectable()
export class MomentDateFormatter extends NgbDateParserFormatter {
  isDayFirst: boolean = false;

  constructor(@Inject(DATE_OPTIONS) readonly dateOption: DateOptions){
    super();
  }

  parse(value: string): NgbDateStruct {
    let result: NgbDateStruct | null = null;
    if (value) {
      value = value.trim();   
      
      if(!moment(value, this.dateOption.NgbDateFormat, true).isValid()){

        if( this.isDayFirst ){
          const val = value.replace(/([ ,/-]+)/g, '/');
          const date = val.split('/');
          const month = date[1];
          const day = date[0];
          const year = date[2];
          value = moment(`${month}/${day}/${year}`).format(this.dateOption.NgbDateFormat);
        }
        else{
          value = moment(value).format(this.dateOption.NgbDateFormat);
        }
      }

      const mdt = moment(value);
      result = { year: mdt.year(), month: mdt.month() + 1, day: mdt.date() };
    }
    return result as NgbDateStruct;
  }

  format(date: NgbDateStruct): string {
    if (!date) return '';
    let mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) return '';
    return mdt.format(this.dateOption.NgbDateFormat);
  }

}
