import { Component, OnInit, Input } from '@angular/core';
import { TicketWithBalanceModel, TopUpTicketsStatus } from '@fgb/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'fgb-ticket-unloaded-list',
  templateUrl: './ticket-unloaded-list.component.html',
  styleUrls: ['../ticket-topup-list.scss']
})
export class TicketUnloadedListComponent implements OnInit {
  @Input() unloadedTickets$: Observable<TicketWithBalanceModel[]>;
  activeUnloadedTickets$: Observable<TicketWithBalanceModel[]>;
  blockedUnloadedTickets$: Observable<TicketWithBalanceModel[]>;

  constructor() {  }

  ngOnInit() {
    this.activeUnloadedTickets$ = this.unloadedTickets$.pipe(
      map(tickets => {
        return tickets.filter(ticket => ticket.Status === TopUpTicketsStatus.UnloadedActive);
      })
    );

    this.blockedUnloadedTickets$ = this.unloadedTickets$.pipe(
      map(tickets => {
        return tickets.filter(ticket => ticket.Status === TopUpTicketsStatus.UnloadedBlocked);
      })
    );
  }
}
