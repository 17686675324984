import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import {
  BalanceQuery,
  CartQuery,
  CartService,
  config,
  ErrorStateService,
  MarketplaceItemCart,
  MarketplaceItemStatus,
  MemberDetails,
  MemberQuery,
  MemberService,
} from '@fgb/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'fgb-cart-checkout-page',
  templateUrl: './cart-checkout-page.component.html',
  styleUrls: ['./cart-checkout-page.component.scss'],
})
export class CartCheckoutPageComponent implements OnInit, OnDestroy {
  totalPoints: number;
  totalItems$: Observable<number>;
  items$: Observable<MarketplaceItemCart[] | undefined>;
  editingMemberAddress: boolean = false;
  missingDetails: string = '';
  memberDetail$: Observable<MemberDetails | undefined>;
  memberAddressForm: FormGroup;
  portalId = '';
  hasSubmittedMemberAddress: boolean = false;
  memberDetails: MemberDetails;
  validCheckout: boolean;
  currentBalance: number;
  statusText: string;
  private _destroyed$ = new Subject();
  constructor(
    private location: Location,
    private cartQuery: CartQuery,
    private cartService: CartService,
    private errorService: ErrorStateService,
    private memberQuery: MemberQuery,
    private formBuilder: FormBuilder,
    private memberService: MemberService,
    private balanceQuery: BalanceQuery,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.errorService.clearErrors();

    this.cartQuery
      .updateBasketPointsTotal()
      .pipe(
        tap((val) => {
          this.totalPoints = val ? val : 0;
        })
      )
      .toPromise();

    this.balanceQuery
      .selectPurse(config.purseConfig.virtualLoyalty)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((bal) => {
        this.currentBalance = bal ?? 0;
      });
    this.items$ = this.cartQuery.selectAllMarketplaceCartData().pipe(
      tap((items) => {
        this.validCheckout = this._validateItems(items);
      })
    );
    this.totalItems$ = this.cartQuery.updateTotalItems();

    // get address from member details
    this.getMemberDetailAddress();
  }

  // go back to previous page from checkout page
  goBack() {
    this.location.back();
  }

  // make payment on checkout page, show error if member address data is invalid
  payNow() {
    this.errorService.clearErrors();
    if (!this.memberAddressForm.valid) {
      this.missingDetails = 'Please ensure there is no missing address details and that your phone number only contain numbers';
      this.errorService.addError(this.missingDetails);
    } else {
      this.errorService.clearErrors();
      this.cartService.purchaseMarketplaceItemsInCart(this.totalPoints);
    }
  }

  /** Start editing the user's member details address. */
  editMemberAddress() {
    this.editingMemberAddress = true;
  }

  /** Clear the member details address form fields. */
  clearAddressForm(addressForm: FormGroup) {
    addressForm.patchValue({
      Street: '',
      Town: '',
      County: '',
      Postcode: '',
      Country: '',
      HomeNumber: '',
    });
  }

  // cancel button which will close and rebuild the form with the original data
  cancelAndRebuildForm() {
    this.editingMemberAddress = false;

    this.memberDetail$ = this.memberQuery.selectMemberDetails().pipe(
      tap((md) => {
        if (md) {
          this.portalId = md.PortalId;
          this.memberAddressForm = this.formBuilder.group({
            AddressName: [md.AddressName],
            Street: [md.Street, Validators.required],
            Town: [md.Town, Validators.required],
            County: [md.County, Validators.required],
            Country: [md.Country, Validators.required],
            PostCode: [md.PostCode, Validators.required],
            HomeNumber: [md.HomeNumber, Validators.required],
          });
        }
      })
    );
  }

  getMemberDetailAddress() {
    this.memberDetail$ = this.memberQuery.selectMemberDetails().pipe(
      tap((md) => {
        if (md) {
          this.memberDetails = md;
          this.portalId = md.PortalId;
          this.memberAddressForm = this.formBuilder.group({
            AddressName: [md.AddressName],
            Street: [md.Street, Validators.required],
            Town: [md.Town, Validators.required],
            County: [md.County, Validators.required],
            Country: [md.Country, Validators.required],
            PostCode: [md.PostCode, Validators.required],
            HomeNumber: [md.HomeNumber, Validators.required],
          });
        }
      })
    );
  }

  saveMemberDetailAddress() {
    this.hasSubmittedMemberAddress = true;

    if (this.memberAddressForm.valid) {
      this.memberDetails = { ...this.memberDetails, ...this.memberAddressForm.value };

      this.memberService.updateMemberDetails(this.portalId, this.memberDetails);
      this.editingMemberAddress = false;
      this.errorService.clearErrors();
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _validateItems(items: MarketplaceItemCart[] | undefined) {
    if (!items || items.length === 0) {
      this.translate
        .stream('checkout.empty-basket')
        .pipe(takeUntil(this._destroyed$))
        .subscribe((data: string) => {
          this.statusText = data;
        });
      return false;
    }

    for (let item of items) {
      switch (item.productData.Status) {
        case MarketplaceItemStatus.SoldOut:
          this.translate
            .stream('marketplace-details.sold-out')
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: string) => {
              this.statusText = data;
            });
          return false;
        case MarketplaceItemStatus.NotEnoughPoints:
          this.translate
            .stream('marketplace-details.not-enough')
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: string) => {
              this.statusText = data;
            });
          return false;
        case MarketplaceItemStatus.ComingSoon:
          this.translate
            .stream('marketplace-details.coming-soon')
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: string) => {
              this.statusText = data;
            });
          return false;
        case MarketplaceItemStatus.MemberLimitReached:
          this.translate
            .stream('marketplace-details.limit-reached')
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: string) => {
              this.statusText = data;
            });
          return false;
      }
    }

    this.translate
      .stream('checkout-claim-now')
      .pipe(takeUntil(this._destroyed$))
      .subscribe((data: string) => {
        this.statusText = data;
      });
    return true;
  }
}
