import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CompanyOfferLanguageModel,
  CompanyOfferModel,
  CompanyOfferService,
  CompanyOfferType,
  ErrorStateService,
  TermsAndConditionsDisplayType,
} from '@fgb/core';
import { OfferTermsModalComponent } from '../offer-terms-modal/offer-terms-modal.component';

@Component({
  selector: 'fgb-offers-details',
  templateUrl: './offers-details.component.html',
  styleUrls: ['./offers-details.component.scss'],
})
export class OffersDetailsComponent implements OnInit, OnChanges {
  @Input() ignoreLink: boolean = false;
  @Input() offer: CompanyOfferModel;
  languageContent: CompanyOfferLanguageModel;
  successMessage: string;
  companyOfferType = CompanyOfferType;
  termsAndConditionsType = TermsAndConditionsDisplayType;

  constructor(
    private modalService: NgbModal,
    private companyOfferService: CompanyOfferService,
    private errorService: ErrorStateService
  ) {}

  ngOnInit() {
    this._updateLanguageContent();
  }

  ngOnChanges() {
    this._updateLanguageContent();
  }

  openOfferTermsModal(offer: CompanyOfferModel): void {
    const modal = this.modalService.open(OfferTermsModalComponent);
    modal.componentInstance.offer = offer;
  }

  _updateLanguageContent() {
    let currentLcid = localStorage.getItem('lcid');

    if (!!currentLcid) {
      this.languageContent = !!this.offer.Content.filter((language) => language.LCID == parseInt(currentLcid as string))[0]
        ? this.offer.Content.filter((language) => language.LCID == parseInt(currentLcid as string))[0]
        : this.offer.Content[0];
    } else {
      this.languageContent = this.offer.Content[0];
    }
  }

  async claimOffer(offerId: number) {
    await this.companyOfferService
      .claimOffer(offerId)
      .then((response) => {
        if (response.ErrorNumber > 0) {
          this.errorService.clearErrors();
          this.errorService.addError(response.ErrorDescription);
        } else if (response.RedirectUrl) {
          this.openWindow(response.RedirectUrl);
        } else {
          this.successMessage = response.ErrorDescription;
        }
      })
      .catch((error) => {
        this.errorService.clearErrors();
        this.errorService.addError(error.Message);
      });
  }

  openWindow(link: string) {
    window.open(link);
  }
}
