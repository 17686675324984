<div class="banner" role="banner">
  <h1>{{ 'games.page.score.predictor.title' | translate }}</h1>
</div>

<fgb-game-nav></fgb-game-nav>

<div class="container">
  <ng-container *ngIf="scorePredictors$ | async as scorePredictors">
    <div *ngIf="!scorePredictors.length">
      <div class="no-items w-100 text-center">
        <div class="icon material-icons-two-tone no-results-placeholder-icon text-center" aria-hidden="true">scoreboard</div>
        <div class="no-results-placeholder-title font-weight-bold text-primary text-uppercase">
          {{ 'score.predictor.placeholder.title' | translate }}
        </div>
        <div class="no-results-placeholder-text text-info mb-5">{{ 'score.predictor.placeholder.description' | translate }}</div>
      </div>
    </div>
    <div class="score-predictor-owl">
      <fgb-score-predictor-list></fgb-score-predictor-list>
    </div>
  </ng-container>
  <fgb-score-predictor-rules></fgb-score-predictor-rules>
</div>
