<div class="card p-4">
  <div class="card-body row">
    <div class="col-md-8">
      <h4 *ngIf="claimLimit; else noLimit">YOU HAVE {{numberClaimed}}/{{claimLimit}} CHOICE BENEFITS</h4>
      <ng-template #noLimit><h4>YOU HAVE UNLIMITED CHOICE BENEFITS</h4></ng-template>
      <p>As a big member, we are providing you with the opportunity to customize your membership!</p>
    </div>
    <div class="col-md-4">
      <h4>Choose your benefits from the list below</h4>
    </div>
  </div>
</div>
