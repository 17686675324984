import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Benefit, BenefitQuery } from '@fgb/core';
import { tap, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'fgb-benefits-page',
  templateUrl: './benefits-page.component.html',
  styleUrls: ['./benefits-page.component.scss'],
})
export class BenefitsPageComponent implements OnInit {
  claimLimit: number | undefined = undefined;
  benefits$: Observable<Benefit[]>;
  claimedBenefits$: Observable<Benefit[]>;
  numberOfBenefitsClaimed: number;

  constructor(private benefitQuery: BenefitQuery, private router: Router) {}

  ngOnInit() {
    this.claimLimit = this.benefitQuery.getClaimLimit();

    if (this.claimLimit == null) {
      this.router.navigate(['/']);
    }

    this.benefits$ = this.benefitQuery.selectBenefits().pipe(
      map((benefits) => {
        benefits = benefits.filter((b) => this.benefitQuery.shouldShowBenefit(b));
        benefits = benefits.sort((a, b) => {
          //sort to show active first and then non active at the end
          if (!this.benefitQuery.isActiveBenefit(a) && a.ShowWhenNotActive) {
            return 1;
          }

          if (!this.benefitQuery.isActiveBenefit(b) && b.ShowWhenNotActive) {
            return -1;
          }

          if (a.Status < b.Status) {
            return -1;
          }

          if (a.Status > b.Status) {
            return 1;
          }

          return 0;
        });

        return benefits;
      })
    );
    this.claimedBenefits$ = this.benefitQuery.selectClaimedBenefits().pipe(tap((b) => (this.numberOfBenefitsClaimed = b.length)));
  }

  isClaimLimitReached(): boolean {
    return this.claimLimit !== undefined && this.claimLimit <= this.numberOfBenefitsClaimed;
  }
}
