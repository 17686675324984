import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { PredictorAnswerType, Survey } from '@fgb/core';

@Component({
  selector: 'fgb-score-predictor-completed-processed',
  templateUrl: './score-predictor-completed-processed.component.html',
  styleUrls: ['./score-predictor-completed-processed.component.scss'],
})
export class ScorePredictorCompletedProcessedComponent implements OnInit, OnChanges {
  @Input() survey: Survey;
  @Input() hasSelectedCorrect: boolean;
  @Input() pointsWon: number;

  homeAnswer: string | undefined;
  awayAnswer: string | undefined;

  homeScore: string | undefined;
  awayScore: string | undefined;

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    const home = this.survey.SurveyAnswers.find((x) => x.AnswerType === PredictorAnswerType.Home);
    if (home) {
      this.homeAnswer = home.AnswerValue;
      this.homeScore = home.Value;
    }

    const away = this.survey.SurveyAnswers.find((x) => x.AnswerType === PredictorAnswerType.Away);
    if (away) {
      this.awayAnswer = away.AnswerValue;
      this.awayScore = away.Value;
    }
  }
}
