import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'fgb-lotto-detail-page',
  templateUrl: './lotto-detail-page.component.html',
  styleUrls: ['./lotto-detail-page.component.scss']
})
export class LottoDetailPageComponent implements OnInit {
  id: number;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.id = +(this.route.snapshot.paramMap.get('id') || 0);
  }
}
