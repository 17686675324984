import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FantasyGame, FantasyPlayer } from '@fgb/core';

import * as _moment from 'moment';
const moment = _moment;

@Component({
  selector: 'fgb-fantasy-game-panel',
  templateUrl: './fantasy-game-panel.component.html',
  styleUrls: ['./fantasy-game-panel.component.scss'],
})
export class FantasyGamePanelComponent implements OnInit, OnChanges {
  constructor() {}

  @Output() button = new EventEmitter<any>();

  @Input() game: FantasyGame;
  @Input() buttonState: 'player-link' | 'no-player-link' | 'make-selection' | 'confirm' | 'changing-player' = 'no-player-link';
  @Input() isGamePending: boolean = false;
  @Input() horizontal: boolean = true;
  selectedPlayer: FantasyPlayer | undefined;
  gameDate: string;
  gameTime: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.game) {
      const game = changes.game.currentValue as FantasyGame;
      this.selectedPlayer = game.GamePlayers.find((p) => p.IsSelected);
    }
  }

  ngOnInit() {
    this.gameDate = moment.utc(this.game.GameDate).local().format('MMM DD');
    this.gameTime = moment.utc(this.game.GameDate).local().format('LT');
  }

  buttonClick() {
    this.button.emit('pressed');
  }
}
