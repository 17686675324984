import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { TopupTicketStore } from '@fgb/core';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'fgb-top-up-ticket',
  templateUrl: './top-up-ticket.component.html',
  styleUrls: ['./top-up-ticket.component.scss']
})
export class TopUpTicketComponent implements OnInit, OnDestroy {
  @Input() cardLayout: boolean;
  @Output() topUpAmountSelected = new EventEmitter<number>();
  topUpAmounts$: Observable<number[]>;
  selectOption: number;
  showTopUp = false;

  firstTopUp: Subscription;

  constructor(private topupTicketStore: TopupTicketStore) {
    this.getTopUpAmounts();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.firstTopUp) {
      this.firstTopUp.unsubscribe();
    }
  }

  getTopUpAmounts() {
    this.topUpAmounts$ = this.topupTicketStore.topUpAmounts$;
  }

  selectTopUpAmount() {
    this.topUpAmountSelected.emit(+this.selectOption);
  }

  resetToUpAmount() {
    this.toggle();
    this.topUpAmountSelected.emit(undefined);
  }

  toggle() {
    this.showTopUp = !this.showTopUp;
    this.topUpAmountSelected.emit(+this.selectOption);

    this.firstTopUp = this.topUpAmounts$.pipe(take(1)).subscribe(amounts => {
      if (this.selectOption === undefined && amounts.length > 0) {
        this.selectOption = amounts[0];
        this.topUpAmountSelected.emit(this.selectOption);
      }
    });
  }
}
