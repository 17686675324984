import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-test-pop-home-page',
  templateUrl: './test-pop-home-page.component.html',
  styleUrls: ['./test-pop-home-page.component.scss']
})
export class TestPopHomePageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
