import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-notification-management-page',
  templateUrl: './notification-management-page.component.html',
  styleUrls: ['./notification-management-page.component.scss']
})
export class NotificationManagementPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
