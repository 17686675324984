<div class="container">
  <!-- Desktop heading -->
  <div class="desktop-heading">
    <fgb-ticket-nav></fgb-ticket-nav>
    <div class="row">
      <div class="col-md-4">
        <div class="member-card p-3">
          <fgb-member-card></fgb-member-card>
        </div>
      </div>
      <div class="col-md-8 trans-history">
        <div class="history-selector p-3 history-card">
          <h2>TRANSACTIONS HISTORY</h2>
          <div class="history selector row">
            <div class="col-md-4">
              <div class="input-group">
                <input
                  class="form-control"
                  name="startDate"
                  matInput
                  [(ngModel)]="startDate"
                  placeholder="Start Date"
                  ngbDatepicker
                  [maxDate]="endDate"
                  #startDatePicker="ngbDatepicker"
                  (click)="startDatePicker.toggle()"
                />
              </div>
            </div>

            <div class="col-md-1 text-center">
              <span class="icon icon-swap-horizontal"></span>
            </div>

            <div class="col-md-4">
              <div class="input-group">
                <input
                  ngbDatepicker
                  #endDatePicker="ngbDatepicker"
                  class="form-control"
                  name="endDate"
                  matInput
                  [(ngModel)]="endDate"
                  [maxDate]="today"
                  [minDate]="startDate"
                  placeholder="End Date"
                  (click)="endDatePicker.toggle()"
                />
              </div>
            </div>

            <div class="col-md-3 text-center">
              <button (click)="getTransactions()" class="search-button btn btn-primary btn-block">Search</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 ticketing-eCash-balance-banner">
        <fgb-ecash-balance></fgb-ecash-balance>
      </div>
    </div>
  </div>
  <ng-container *ngIf="transactions">
    <div *ngFor="let day of dayKeys">
      <div class="transaction-date">{{ day }}</div>
      <div
        *ngFor="let transaction of transactions[day]"
        class="transaction"
        [ngClass]="{ 'positive-transaction': transaction.Amount >= 0 }"
      >
        <div class="transactionDescription">{{ transaction.TransactionDescription }}</div>
        <div class="transactionAmount">
          {{ transaction.Amount | FortressCurrencyPipe: 'USD' }}
        </div>
        <div class="transactionTime">{{ transaction.TransactionDate | date: 'hh:mm a' | uppercase }}</div>
        <div style="clear: both"></div>
      </div>
    </div>
  </ng-container>

  <!-- No Transactions -->
  <div class="no-transaction" *ngIf="transactions && (!dayKeys || dayKeys.length === 0)">No Transactions</div>

  <!-- After Clicking Different Tab -->
  <div class="no-transaction" *ngIf="!transactions">Click above to search</div>
</div>
