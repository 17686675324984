<ng-container *ngIf="fantasyHistory$ | async as fantasyHistory">
  <ng-container *ngIf="fantasyHistory.length > 0; else noPlayerHistory">
    <div class="card shadow border-0 rounded px-2 mx-1">
      <ng-container *ngFor="let item of fantasyHistory">
        <fgb-fantasy-history-list-item [fantasyHistoryItem]="item"></fgb-fantasy-history-list-item>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #noPlayerHistory>
    <div class="no-items w-100 text-center mt-lg-5 pt-lg-4">
      <div
        class="icon svg no-results-placeholder-icon font-weight-bold text-center mb-4"
        [inlineSVG]="'assets/images/placeholders/no-pick-a-player.svg'"
      ></div>
      <h5 class="text-primary text-center mb-0 font-weight-bold text-uppercase">
        {{ 'pick.player.history.placeholder.title' | translate }}
      </h5>
      <p class="text-info text-center font-size-14">{{ 'pick.player.history.placeholder.description' | translate }}</p>
    </div>
  </ng-template>
</ng-container>
