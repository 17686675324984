import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WorksheetQuery, WorksheetService } from '@fgb/core';

@Component({
  selector: 'fgb-worksheet-list',
  templateUrl: './worksheet-list.component.html',
  styleUrls: ['./worksheet-list.component.scss'],
})
export class WorksheetListComponent implements OnInit {
  isList: boolean = true;
  worksheets$ = this.worksheetQuery.getWorksheetByRoute(this.router.url.replace('/', ''));

  constructor(private worksheetService: WorksheetService, private worksheetQuery: WorksheetQuery, private router: Router) {}

  ngOnInit() {
    if (!this.worksheetQuery.hasRoute(this.router.url.replace('/', ''))) {
      this.worksheetService.fetchWorksheetByRoute(this.router.url.replace('/', '')).toPromise();
    }
  }
}
