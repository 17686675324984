import { Component, OnInit, Input } from '@angular/core';
import { LoadScripts, DynamicScript } from 'src/app/shared/utilities/load-scripts.function';
declare var SVPDynamicPlayer: any;

@Component({
  selector: 'fgb-svp-player',
  templateUrl: './fgb-svp-player.component.html',
  styleUrls: ['./fgb-svp-player.component.scss']
})
export class FgbSVPPlayerComponent implements OnInit {

  @Input() streamKey: string;
  
  constructor() { }

  ngOnInit() {
    LoadScripts([{ src: "http://play.streamingvideoprovider.com/js/dplayer.js"}] as DynamicScript[])
    .then(() => {

      let svpOptions = {
        clip_id: this.streamKey,
        transparent:"false", 
        pause:"0", 
        repeat:"", 
        bg_color:"#505050", 
        fs_mode:"2", 
        no_controls:"", 
        brand_new_window:"1", 
        auto_hide:"", 
        stretch_video:"", 
        referer:"https://www.streamingvideoprovider.com/streaming-video-players.html", 
        ext:"", 
        preview: "", 
        start_volume:"100", 
        skinAlpha:"80", 
        colorBase:"#202020", 
        colorIcon:"#FFFFFF", 
        colorHighlight:"#ff9b0f", 
        direct:"true", 
        initialBufferTime:"2", 
        bufferTime:"2", 
        expandedBufferTime:"4", 
        viewers_limit:0, 
        cc_position:"bottom", 
        cc_positionOffset:70, 
        cc_multiplier:0.03, 
        cc_textColor:"#ffffff", 
        cc_textOutlineColor:"#ffffff", 
        cc_bkgColor:"#000000", 
        cc_bkgAlpha:70, 
        aspect_ratio: "16:9", 
        play_button: "0", 
        play_button_style: "pulsing", 
        sleek_player: "0" 
      } 

      
      let player = new SVPDynamicPlayer("svp-player_" + this.streamKey, "", "100%", "100", {use_div:"player_flash",skin:"3"}, svpOptions); 
      player.execute(); 
    });
  }
}