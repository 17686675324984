import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'fgb-vimeo-player',
  templateUrl: './fgb-vimeo-player.component.html',
  styleUrls: ['./fgb-vimeo-player.component.scss']
})
export class FgbVimeoPlayerComponent implements OnInit {

  @Input() streamKey: string;
  safeUrl : SafeResourceUrl;
  
  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + this.streamKey);
  }
}