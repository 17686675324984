<div class="full-page">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="panel rounded">
          <ng-container *ngIf="this.availableAccounts.length > 0; else noAccounts">
            <h2>Choose Account</h2>
            <p>Please select one of your accounts below to proceed.</p>

            <div class="table-container">
              <table>
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Email Address</td>
                    <td>Account Number</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let account of availableAccounts">
                    <td>{{ account.FirstName }} {{ account.Surname }}</td>
                    <td>{{ account.EmailAddress1 }}</td>
                    <td>{{ account.ExternalRefNumber }}</td>
                    <td>
                      <ng-container [ngSwitch]="account.UserAccountStatus">
                        <div *ngSwitchCase="0">
                          <a class="btn btn-danger" [href]="account.EmailAddress2">Register</a>
                        </div>
                        <div *ngSwitchCase="1">
                          <a class="btn btn-success" [href]="account.EmailAddress2">Login</a>
                        </div>
                        <div *ngSwitchCase="2">
                          <a class="btn btn-muted" [href]="account.EmailAddress2">Activate</a>
                        </div>
                        <div *ngSwitchDefault>
                          <i>n/a</i>
                        </div>
                      </ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noAccounts>
  <p class="my-2">
    <i>
      We're sorry but an error has occurred. We could not find any accounts for you to choose from.
      <br />
      Please click below to enter your credentials again.
    </i>
  </p>
  <a [routerLink]="['/login']" class="btn btn-primary">Login</a>
</ng-template>
