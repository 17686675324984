import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PurchaseService, PurchaseOrderItemModel } from '@fgb/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'fgb-portal-product-success-page',
  templateUrl: './portal-product-success-page.component.html',
  styleUrls: ['./portal-product-success-page.component.scss'],
})
export class PortalProductSuccessPageComponent implements OnInit {
  orderDetails$: Observable<PurchaseOrderItemModel>;

  constructor(private route: ActivatedRoute, private purchaseService: PurchaseService) {}

  ngOnInit() {
    this.orderDetails$ = this.route.queryParams.pipe(
      map((params: Params) => params['orderId']),
      switchMap((orderId: string) => {
        return this.purchaseService.fetchOrderById(orderId);
      })
    );
  }
}
