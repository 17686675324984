import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PortalProductSubscription } from '@fgb/core';

@Component({
  selector: 'fgb-subscriptions-list-item-subscribed',
  templateUrl: './subscriptions-list-item-subscribed.component.html',
  styleUrls: ['./subscriptions-list-item-subscribed.component.scss'],
})
export class SubscriptionsListItemSubscribedComponent implements OnInit {
  @Input() subscribedProduct: PortalProductSubscription;
  currentSubscribedProduct: PortalProductSubscription;
  currentLcid: string | null = '';
  constructor(private modalService: NgbModal) {
    this.currentLcid = localStorage.getItem('lcid');
  }

  ngOnInit() {
    this.updateStatus();
  }

  updateStatus() {
    if (this.subscribedProduct.ProductType === 3) {
      this.currentSubscribedProduct = this.subscribedProduct;
    }
  }

  openProductDescription(content: any) {
    this.modalService.open(content, { centered: true });
  }
}
