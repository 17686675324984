<div class="checkout-table">
  <div class="row">
    <div class="col-lg-12">
      <div class="topup-ticket-header p-2">
        <div class="row">
          <div class="ticket-barcode col-3">
            Ticket Barcode
          </div>
          <div class="ticket-section col w-20">
            Section
          </div>
          <div class="ticket-row col-1">
            Row
          </div>
          <div class="ticket-seat col-1">
            Seat
          </div>
          <div class="ticket-balance col-2">
            Balance
          </div>
          <div class="ticket-transaction-amount col w-20">
            Transaction Amount
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngFor="let ticket of topUpTickets$ | async">
    <div class="row">
      <div class="col-lg-12">
        <div class="row p-2 ticket-item">
          <div class="item-barcode col-3">
            {{ticket.barcodeText}}
          </div>
          <div class="item-section col w-20">
            {{ticket.stand}}
          </div>
          <div class="item-row col-1">
            {{ticket.row}}
          </div>
          <div class="item-seat col-1">
            {{ticket.seat}}
          </div>
          <div class="item-balance col-2">
            {{ticket.balance | FortressCurrencyPipe: 'USD'}}
          </div>
          <div class="item-transaction-amount col w-20">
            {{ticket.amount | FortressCurrencyPipe: 'USD'}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="checkout-mobile" *ngIf="topUpTickets$ | async as topUpTickets">
  <div *ngFor="let ticket of topUpTickets">
    <div class="heading">
      <span>Ticket Barcode</span>
      <span>{{ticket.barcodeText}}</span>
    </div>
    <div class="heading">
      <span>Section</span>
      <span>{{ticket.stand}}</span>
    </div>
    <div class="heading">
      <span>Row</span>
      <span>{{ticket.row}}</span>
    </div>
    <div class="heading">
      <span>Seat</span>
      <span>{{ticket.seat}}</span>
    </div>
    <div class="heading">
      <span>Current Balance</span>
      <span>{{ticket.balance | FortressCurrencyPipe: 'USD'}}</span>
    </div>
    <div class="heading">
      <span>Transaction Amount</span>
      <span class="item-transaction-amount">{{ticket.amount | FortressCurrencyPipe: 'USD'}}</span>
    </div>
    <hr *ngIf="topUpTickets.length > 1">
  </div>
</div>
