<div class="row p-3">
  <div class="col-3">
    <img src="{{ benefit.ImageFileName | contentImage: '1' }}" />
  </div>
  <div class="col-9">
    <h5>Are you sure you want to choose this benefit?</h5>
    <div class="benefitName">{{ benefit.Name }}</div>
    <button class="btn btn-danger m-2" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary" (click)="claimBenefit(benefit)">Confirm</button>
  </div>
</div>
