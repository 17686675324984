import { Component, OnInit } from '@angular/core';
import { CartQuery } from '@fgb/core';

@Component({
  selector: 'fgb-cart-checkout-success-page',
  templateUrl: './cart-checkout-success-page.component.html',
  styleUrls: ['./cart-checkout-success-page.component.scss'],
})
export class CartCheckoutSuccessPageComponent implements OnInit {
  constructor(private cartQuery: CartQuery) {}

  ngOnInit() {
    let marketplaceCart = this.cartQuery.getMemberMarketplaceCart();
    this.cartQuery.removeItems(marketplaceCart);
  }
}
