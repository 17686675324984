import { Component, OnInit, Input, OnChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import {
  Survey,
  SurveyService,
  ScorePredictorAnswer,
  PredictorAnswerType,
  ScorePredictorStatus,
  SurveyAnswer,
  SurveyQuery,
  SurveyType,
} from '@fgb/core';
import * as _moment from 'moment';
import { map, take, tap } from 'rxjs/operators';
const moment = _moment;
@Component({
  selector: 'fgb-score-predictor-item',
  templateUrl: './score-predictor-item.component.html',
  styleUrls: ['./score-predictor-item.component.scss'],
})
export class ScorePredictorItemComponent implements OnInit, OnChanges {
  @Input() survey: Survey;
  @Output() removeScorePredictor = new EventEmitter<null>();

  homeTeam: number;
  awayTeam: number;
  status: ScorePredictorStatus = ScorePredictorStatus.ComingSoon;
  scorePredictorStatus = ScorePredictorStatus;

  homeTeamValue: number = 0;
  awayTeamValue: number = 0;
  selectedAnswers: SurveyAnswer[] = [];

  hasSelectedCorrect: boolean = false;
  pointsWon: number;

  constructor(private surveyService: SurveyService, private cdRef: ChangeDetectorRef, private surveyQuery: SurveyQuery) {}

  ngOnInit() {}

  ngOnChanges() {
    this.status = this.surveyService.scorePredictorStatus(this.survey);
    this.defineTeamValues();
    this.defineAnswers();
  }

  async submit() {
    let surveyId: number = this.survey.SurveyId;
    let answers: ScorePredictorAnswer[] = [];

    answers.push({
      surveyId,
      answerType: PredictorAnswerType.Home,
      answerValue: this.homeTeamValue.toString(),
    });

    answers.push({
      surveyId,
      answerType: PredictorAnswerType.Away,
      answerValue: this.awayTeamValue.toString(),
    });

    await this.surveyService.postScorePredictorAnswers(this.survey.SurveyId, answers);

    this.status = this.scorePredictorStatus.Answered;
  }

  calculateEntryEnd() {
    if (this.survey.HasBeenAnsweredByUser) {
      this.status = ScorePredictorStatus.Answered;
    } else {
      this.removeSurvey(this.survey.SurveyId);
    }
  }

  private removeSurvey(surveyId: number) {
    this.surveyService.removeSurvey(surveyId);
    this.cdRef.detectChanges();
  }

  editAnswer() {
    this.status = this.scorePredictorStatus.EntryOpen;
  }

  private defineAnswers(): void {
    if (this.status === ScorePredictorStatus.Answered && this.survey.ProcessStatus === 2) {
      this.selectedAnswers = this.survey.SurveyAnswers.filter((sa) => sa.UserSelected);
      this.hasSelectedCorrect = this.selectedAnswers.filter((a) => a.AnswerValue === a.Value).length === 2;
      if (this.hasSelectedCorrect) {
        this.pointsWon = this.selectedAnswers[0].PointValue;
      }
    }
  }

  private defineTeamValues(): void {
    this.homeTeam = this.survey.SurveyAnswers.findIndex((x) => x.AnswerType === PredictorAnswerType.Home);
    this.awayTeam = this.survey.SurveyAnswers.findIndex((x) => x.AnswerType === PredictorAnswerType.Away);

    if (this.survey.SurveyAnswers[this.homeTeam] && this.survey.SurveyAnswers[this.homeTeam].UserSelected) {
      this.homeTeamValue = +this.survey.SurveyAnswers[this.homeTeam].AnswerValue;
    }

    if (this.survey.SurveyAnswers[this.awayTeam] && this.survey.SurveyAnswers[this.awayTeam].UserSelected) {
      this.awayTeamValue = +this.survey.SurveyAnswers[this.awayTeam].AnswerValue;
    }
  }

  canEdit(): boolean {
    const endDate = this.survey.SeparateEntryWindow
      ? moment.utc(this.survey.EntryEndDate).local()
      : moment.utc(this.survey.EndDate).local();
    return this.survey.ProcessStatus == 0 && Math.floor(endDate.diff(moment(), 'seconds')) > 0;
  }

  updateStatusChange(newStatus: ScorePredictorStatus) {
    const wasUpdatedDuringEdit =
      this.status == this.scorePredictorStatus.EntryOpen && newStatus == this.scorePredictorStatus.Answered;

    if (wasUpdatedDuringEdit) {
      this.updateScoresFromStore();
    }
    this.status = newStatus;
  }

  updateScoresFromStore() {
    this.surveyQuery
      .selectSurveys(SurveyType.ScorePredictor)
      .pipe(
        take(1),
        map((surveys) => surveys.filter((item) => item.SurveyId === this.survey.SurveyId)[0]),
        tap((item) => {
          this.survey = item;
          this.defineTeamValues();
        })
      )
      .subscribe();
  }
}
