<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="currentBalance > 0; else noBonusCredit">
    <ng-container *ngIf="partners$ | async as partners">
      <!--carousel of partners-->
      <ng-container *ngIf="partners.length > 0; else noPartners">
        <p class="font-weight-bold font-size-14 pt-2">Choose your recipient</p>
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let partner of partners">
            <ng-template carouselSlide>
              <div
                class="card points-transfer-carousel-item pt-3 shadow align-items-center mx-auto"
                (click)="selectPartner(partner)"
                [ngClass]="{
                  'bg-primary text-white svg svg-white': partner === selectedPartner
                }"
              >
                <div class="icon svg mb-3 points-transfer-icon" [inlineSVG]="'assets/images/icons/filled-account-icon.svg'"></div>
                <p class="mb-2 font-size-14">{{ partner.FullName }}</p>
                <p class="font-size-14"><span class="font-weight-bold">Acc No:</span> {{ partner.MemberId }}</p>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </ng-container>

      <ng-template #noPartners>
        <p class="font-weight-bold font-size-14">You currently have no partners available!</p>
      </ng-template>
    </ng-container>
  </ng-container>

  <hr class="my-4" />

  <div
    [ngClass]="{
      'disabled-number-input': !selectedPartner
    }"
  >
    <p class="font-weight-bold font-size-12">Please select the amount of Bonus Credit you <br />want to transfer.</p>
    <div class="number-input-container mx-auto">
      <fgbcl-number-input
        [isActive]="true"
        [minValue]="minTransfer"
        [maxValue]="currentBalance"
        [incrementValue]="1"
        [minMessage]="'$1 is the minimum'"
        [maxMessage]="'You do not have enough'"
        [(amount)]="transferValue"
      >
      </fgbcl-number-input>
    </div>

    <div class="my-3">
      <button class="btn btn-muted btn-bonus-value border mx-2 p-0" (click)="transferValue = 5">$5</button>
      <button class="btn btn-muted btn-bonus-value border mx-2 p-0" (click)="transferValue = 10">$10</button>
      <button class="btn btn-muted btn-bonus-value border mx-2 p-0" (click)="transferValue = 15">$15</button>
      <button class="btn btn-muted btn-bonus-value border mx-2 p-0" (click)="transferValue = 20">$20</button>
    </div>

    <button
      class="btn btn-primary send-btn mb-3"
      confirmModal
      [confirmContent]="confirmBonusTranser"
      (confirm)="transferBonusCredit(transferValue)"
      [disabled]="currentBalance <= 0 || transferValue > currentBalance || transferValue < minTransfer"
    >
      Send
    </button>
  </div>

  <ng-template #confirmBonusTranser>
    <p class="font-weight-bold text-center">
      Are you sure you want to transfer
      <span class="text-success">{{ transferValue | FortressCurrencyPipe }} Bonus Credit</span> to
      <span class="text-danger">{{ selectedPartner.FullName }}</span
      >?
    </p>
  </ng-template>

  <ng-template #noBonusCredit>
    <p class="font-weight-bold font-size-14">You currently have no bonus credit to transfer!</p>
  </ng-template>
</ng-container>

<ng-container *ngIf="isLoading">
  <fgbcl-loading></fgbcl-loading>
</ng-container>

<!--modal content after transfer has been attempted-->
<ng-template #successTransfer let-modal>
  <div class="text-center transfer-status-modal">
    <p class="font-weight-bold text-center text-success my-4">Successfully Processed!</p>
    <button class="btn btn-success font-weight-bold mt-4" (click)="modal.dismiss('close')">Close</button>
  </div>
</ng-template>
<ng-template #failedTransfer let-modal>
  <div class="text-center transfer-status-modal">
    <p class="font-weight-bold text-center text-danger my-4">Unsuccessful Transfer!</p>
    <button class="btn btn-danger font-weight-bold mt-4" (click)="modal.dismiss('close')">Close</button>
  </div>
</ng-template>
