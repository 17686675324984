import { Component, OnInit, Injectable, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { LoadScripts, DynamicScript } from 'src/app/shared/utilities/load-scripts.function';
import { delay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FacebookService {
  static behaviourSubject: BehaviorSubject<any>;

  constructor() {}

  addVideo(): Observable<any> {
    if (FacebookService.behaviourSubject == null) {
      FacebookService.behaviourSubject = new BehaviorSubject<any>("initializing");
      
      let srcProtocal = (('https:' == document.location.protocol) ? 'https:' : 'http:');
      let scriptUrl = srcProtocal + '//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2';
      
      LoadScripts([{ src: scriptUrl}] as DynamicScript[]).then(() =>{
        FacebookService.behaviourSubject.next("loaded");
      })
    }

    return FacebookService.behaviourSubject.asObservable();
  }
}

@Component({
  selector: 'fgb-facebook-player',
  templateUrl: './fgb-facebook-player.component.html',
  styleUrls: ['./fgb-facebook-player.component.scss'],
})
export class FgbFacebookPlayerComponent implements OnInit, OnDestroy {
  @Input() streamKey: string;
  @Output() onStateChange = new EventEmitter();
  
  private subscription: Subscription;
  public static analyticsInterval = 0;

  constructor(private facebookService: FacebookService) {}

  ngOnInit() {
    this.subscription = this.facebookService
      .addVideo()
      .pipe(delay(100))
      .subscribe((facebookService) => {
        if (facebookService == 'loaded') {
          
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
