import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BalanceQuery, config, MemberDetails, MemberQuery } from '@fgb/core';

@Component({
  selector: 'fgb-points-management-placeholder',
  templateUrl: './points-management-placeholder.component.html',
  styleUrls: ['./points-management-placeholder.component.scss'],
})
export class PointsManagementPlaceholderComponent implements OnInit {
  loyaltyPointsCard$: Observable<number | undefined>;
  memberDetails$: Observable<MemberDetails | undefined>;

  constructor(private balanceQuery: BalanceQuery, public memberQuery: MemberQuery) {}

  ngOnInit() {
    this.loyaltyPointsCard$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
  }
}
