import { Component, OnInit, Input } from '@angular/core';
import { Benefit } from '@fgb/core';

@Component({
  selector: 'fgb-benefit-item-list',
  templateUrl: './benefit-item-list.component.html',
  styleUrls: ['./benefit-item-list.component.scss']
})
export class BenefitItemListComponent implements OnInit {

  @Input() benefits: Benefit[];
  @Input() disabled = false;

  constructor() { }

  ngOnInit() {
  }

}
