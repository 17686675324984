import { Component, OnInit } from '@angular/core';
import { EcashService, MemberQuery, config } from '@fgb/core';
import { TopUpViewModel } from '@fgb/core';
import { TopUpAmountTypeId } from '@fgb/core';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'fgb-topup',
  templateUrl: 'topup.component.html',
  styleUrls: ['./topup.component.scss'],
})
export class TopupComponent implements OnInit {
  amounts$: Observable<number[]>;
  amount: number;
  constructor(private ecashService: EcashService, private memberQuery: MemberQuery, private modalService: NgbModal) {}

  ngOnInit() {
    this.amounts$ = this.ecashService.getTopUpAmounts(TopUpAmountTypeId.TopUpAmount).pipe(
      tap(amounts => {
        if (amounts.length > 0) {
          this.amount = amounts[0];
        }
      })
    );
  }

  setAmount(amount: number) {
    this.amount = amount;
  }

  open(content: any) {
    let model: TopUpViewModel;
    let user = this.memberQuery.getValue().user;

    if (user) {
      model = new TopUpViewModel(
        +user.externalRef,
        this.amount,
        user.portalId,
        user.memberId,
        config.portalUrl + '/ecash/result',
        config.portalUrl + '/ecash/result'
      );
      this.ecashService.topUp([model]);
    }
    this.modalService.open(content);
  }
}
