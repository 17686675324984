import { Component, OnInit, Injectable, Input, Output,EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LoadScripts, DynamicScript } from 'src/app/shared/utilities/load-scripts.function';
import { delay } from 'rxjs/operators';
declare var boxcast : any;

@Injectable({ providedIn: 'root' })
export class BoxCastService
{
  static behaviourSubject: BehaviorSubject<any>;
  
  constructor() {}

  addVideo() : Observable<any> {
    
    if (BoxCastService.behaviourSubject == null) {

      BoxCastService.behaviourSubject = new BehaviorSubject<any>("initializing");
      
      let srcProtocal = (('https:' == document.location.protocol) ? 'https:' : 'http:');
      let scriptUrl = srcProtocal + '//js.boxcast.com/v3.min.js';
      
      LoadScripts([{ src: scriptUrl}] as DynamicScript[]).then(() =>{
        BoxCastService.behaviourSubject.next("loaded");
      })
    }
      
    return BoxCastService.behaviourSubject.asObservable();
  }
}


@Component({
  selector: 'fgb-boxcast-player',
  templateUrl: './fgb-boxcast-player.component.html',
  styleUrls: ['./fgb-boxcast-player.component.scss']
})
export class FgbBoxcastPlayerComponent implements OnInit {
  
  @Input() streamKey: string;
  @Output() onStateChange = new EventEmitter();

  private subscription: Subscription;
  private videoContext: any;

  constructor(private boxCastService: BoxCastService) { }

  ngOnInit() {

    this.subscription = this.boxCastService.addVideo().pipe(delay(1000)).subscribe(boxCastStatus => {

      if (boxCastStatus == "loaded") {

        let onStateChangeFunction = this.onStateChange;
        
        this.videoContext = boxcast("#boxcast_player_" + this.streamKey);
        this.videoContext.loadChannel(this.streamKey, {
            "showTitle": 0, 
            "showDescription": 0, 
            "showHighlights": 0, 
            "showRelated": false, 
            "showCountdown": true, 
            "market": "smb", 
            "showDocuments": false, 
            "showIndex": false, 
            "showDonations": false,
            "onPlayerStateChanged" : function(eventName: string , eventDetails: any) {
              onStateChangeFunction.emit({ eventName, eventDetails} as any);
            }
          });
        }
    });
  }

  ngOnDestroy() {
    this.videoContext.unload();
    this.subscription.unsubscribe();
  }
}
