import { Component, Input, OnInit } from '@angular/core';
import { CompanyOfferLanguageModel, CompanyOfferModel } from '@fgb/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-offer-terms-modal',
  templateUrl: './offer-terms-modal.component.html',
  styleUrls: ['./offer-terms-modal.component.scss'],
})
export class OfferTermsModalComponent implements OnInit {
  @Input() offer: CompanyOfferModel;
  languageContent: CompanyOfferLanguageModel;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {}

  closeModal(): void {
    this.activeModal.close();
  }
}
