<ng-container *ngIf="items$ | async as items">
  <div *ngIf="items.length">
    <div *ngFor="let item of items" class="row">
      <div class="col-2">
        <div
          class="sub-item rounded text-center"
          [ngClass]="{
            'bg-primary': item.productData.ProductType == 1,
            'bg-secondary': item.productData.ProductType == 3
          }"
        >
          <img alt="" class="portal-product-img my-2" src="{{ item.productData.PrimaryImage | contentImage: '6' }}" />
        </div>
      </div>
      <div class="col-10">
        <div class="row">
          <div class="col-12 text-left">
            <p class="font-size-12">
              {{ item.productData.DisplayName }}
            </p>
            <div class="row">
              <div class="col-6">
                <p class="font-size-12">
                  <span class="font-weight-bold"><span [translate]="'checkout.cost'">Cost</span>:</span>
                  {{ item.productData.MerchantPrice / 100 | FortressCurrencyPipe }}
                </p>
              </div>
              <div class="col-6 text-right pr-4">
                <button
                  (click)="remove(item)"
                  class="btn py-0"
                  attr.aria-label="{{ 'cart.remove.item.screenreader' | translate }}"
                >
                  <span class="icon icon-cancel-circle"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="mx-3 w-100" />
    </div>
  </div>
  <div *ngIf="!items.length" class="font-size-14">
    <p [translate]="'checkout.empty-basket'">Your basket is empty!</p>
  </div>
</ng-container>
