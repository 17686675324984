<div class="px-4 pt-3 mb-3">
  <div class="row mb-2 font-weight-bold font-size-12">
    <div class="col-2 col-lg-3">{{ 'pick.player.leaderboard.rank' | translate }}</div>
    <div class="col-6">{{ 'pick.player.leaderboard.name' | translate }}</div>
    <div class="col-4 col-lg-3">{{ 'pick.player.leaderboard.points' | translate }}</div>
  </div>
  <div
    class="font-size-14 mb-2 fantasy-row"
    *ngFor="let entry of fantasyEntries; let i = index"
    [ngClass]="{ 'current-user-row': entry.IsCurrentUser }"
  >
    <hr class="mx-0 my-2" *ngIf="entry.Position > i + 1" />
    <div class="row my-2" [ngClass]="{ 'rounded bg-primary text-white py-2 mx-0 mb-2 current-user': entry.IsCurrentUser }">
      <div class="col-2 col-lg-3 font-weight-bold">
        {{ entry.Position }}
      </div>
      <div class="col-6 text-capitalize" [ngClass]="{ 'text-muted': !entry.IsCurrentUser }">
        {{ entry.FullName }}
      </div>
      <div class="col-3 col-lg-2" [ngClass]="{ 'text-muted': !entry.IsCurrentUser }">
        {{ entry.Points / 100 | FortressCurrencyPipe: 'PTS' }}
      </div>
    </div>
  </div>
</div>
