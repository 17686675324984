<div class="banner" role="banner">
  <h1>{{ 'contacts.page.title' | translate }}</h1>
</div>
<div class="container mt-4">
<div class="row">
  <h3 class="col">{{ 'contacts.page.title' | translate }}</h3>
  <div class="col">
  <button class="btn btn-primary btn-remove-contact float-right" (click)="BackToFixtures()">Back To Fixtures</button>
  </div>
  </div>
  <fgbcl-contacts-management></fgbcl-contacts-management>
</div>
