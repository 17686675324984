<div class="desktop-shadow">
  <div *ifScreenSize="screenType.Desktop">
    <div class="container">
      <div class="tool-bar d-flex flex-row justify-content-between align-items-center py-2">
        <div class="options-link d-flex flex-row">
          <h2 class="my-0">{{ 'offers.toolbar.title' | translate }}</h2>
        </div>
        <div class="options-select d-flex flex-row">
          <fgbcl-offer-search-bar [arrayToFilter]="offers" (searchResultArray)="searchResult($event)"></fgbcl-offer-search-bar>
          <fgbcl-filter-by
            [arrayToFilter]="offers"
            [filterOptions]="filterOptions"
            (filteredArray)="filterArray($event)"
          ></fgbcl-filter-by>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ifScreenSize="screenType.Desktop">
  <div class="container pt-4">
    <div class="view-bar d-flex flex-row justify-content-between align-items-center">
      <div class="font-size-14 font-weight-bold text-grey">{{ 'offers.viewbar.title' | translate }}</div>
      <div class="options-link d-flex flex-row">
        <button
          id="grid-view-btn"
          class="view-bar__option border-right border-grey px-3"
          [ngClass]="view === 'grid' ? 'active' : 'text-info'"
          (click)="changeView('grid')"
        >
          <span
            class="svg"
            [ngClass]="view === 'grid' ? 'svg-primary' : 'svg-info'"
            [inlineSVG]="'assets/images/icons/grid-view.svg'"
          ></span>
          <label>{{ 'offers.viewbar.grid' | translate }}</label>
        </button>
        <button
          id="map-view-btn"
          class="view-bar__option px-3"
          [ngClass]="view === 'map' ? 'active' : 'text-info'"
          (click)="changeView('map')"
        >
          <span
            class="svg"
            [ngClass]="view === 'map' ? 'svg-primary' : 'svg-info'"
            [inlineSVG]="'assets/images/icons/location.svg'"
          ></span>
          <label>{{ 'offers.viewbar.map' | translate }}</label>
        </button>
      </div>
    </div>
  </div>
</div>

<!--On Mobile the Toolbar is split in two-->
<div *ifScreenSize="screenType.Mobile" class="container shadow py-2 mb-3">
  <h2 class="mt-0 mb-3">{{ 'offers.viewbar.title' | translate }}</h2>
  <div class="tool-bar d-flex flex-row justify-content-between">
    <fgbcl-offer-search-bar [arrayToFilter]="offers" (searchResultArray)="searchResult($event)"></fgbcl-offer-search-bar>
    <fgbcl-filter-by
      [arrayToFilter]="offers"
      [filterOptions]="filterOptions"
      (filteredArray)="filterArray($event)"
    ></fgbcl-filter-by>
  </div>
</div>

<div *ifScreenSize="screenType.Mobile" class="container tool-bar mb-3">
  <div class="options-link d-flex d-lg-none flex-row justify-content-around">
    <button
      id="grid-view-btn"
      class="tool-bar__option single btn"
      [ngClass]="view === 'grid' ? 'active' : 'text-info'"
      (click)="changeView('grid')"
      attr.aria-label="{{ 'offers.toolbar.grid.screenreader' | translate }}"
    >
      <span class="svg grid d-inline-block my-0" [inlineSVG]="'assets/images/icons/grid-view.svg'"></span>
      <label class="font-size-14 mb-0">{{ 'offers.toolbar.grid' | translate }}</label>
    </button>
    <button
      id="map-view-btn"
      class="tool-bar__option single btn"
      [ngClass]="view === 'map' ? 'active' : 'text-info'"
      (click)="changeView('map')"
      attr.aria-label="{{ 'offers.toolbar.map.screenreader' | translate }}"
    >
      <span class="svg map d-inline-block my-0" [inlineSVG]="'assets/images/icons/location.svg'"></span>
      <label class="font-size-14 mb-0">{{ 'offers.toolbar.map' | translate }}</label>
    </button>
  </div>
</div>
