<div class="survey-container shadow rounded overflow-hidden" [ngClass]="{ answered: survey.HasBeenAnsweredByUser, 'coming-soon': status === scorePredictorStatus.ComingSoon }">
  <fgb-score-predictor-header
    [scorePredictor]="survey"
    [status]="status"
    (statusChange)="updateStatusChange($event)"
    [canEditAnswer]="canEdit()"
    (removeScorePredictor)="removeScorePredictor.emit()"
  ></fgb-score-predictor-header>
  <div class="survey-body bg-white d-flex flex-column">
    <div class="">
      <!--Information Row-->
      <fgb-score-predictor-body
        [survey]="survey"
        [status]="status"
        [homeTeam]="homeTeam"
        [awayTeam]="awayTeam"
        [hasSelectedCorrect]="hasSelectedCorrect"
        [canEditAnswer]="canEdit()"
        [(homeTeamValue)]="homeTeamValue"
        [(awayTeamValue)]="awayTeamValue"
        (countDownComplete)="calculateEntryEnd()"
      >
      </fgb-score-predictor-body>
    </div>

    <fgb-score-predictor-footer
      [survey]="survey"
      [status]="status"
      [hasSelectedCorrect]="hasSelectedCorrect"
      [pointsWon]="pointsWon"
      [canEdit]="canEdit()"
      (editAnswer)="editAnswer()"
      (submitAnswer)="submit()"
    >
    </fgb-score-predictor-footer>
  </div>
</div>
