<div class="d-none d-lg-block">
  <div class="banner mb-3">
    <h1>{{ 'marketplace.page.title' | translate }}</h1>
  </div>

  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</div>

<div class="container my-5" *ngIf="marketplaceItem">
  <div [innerHTML]="marketplaceItem.TermsAndConditions"></div>
</div>
