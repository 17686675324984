  import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { LoadScripts, DynamicScript } from 'src/app/shared/utilities/load-scripts.function';
declare var Twitch: any;

@Component({
  selector: 'fgb-twitch-player',
  templateUrl: './fgb-twitch-player.component.html',
  styleUrls: ['./fgb-twitch-player.component.scss']
})
export class FgbTwitchPlayerComponent implements OnInit {

  @Input() streamKey: string;
  safeUrl : SafeResourceUrl;
  
  constructor() { }

  ngOnInit() {
    LoadScripts([{ src: "https://player.twitch.tv/js/embed/v1.js"}] as DynamicScript[])
    .then(() => {

      new Twitch.Player("twitch-player_" + this.streamKey, {
        channel: this.streamKey,
        //video: this.streamKey,
        height: "100%",
        width: "100%",
        autoplay: false,
        // only needed if your site is also embedded on embed.example.com and othersite.example.com 
        parent: ["embed.example.com", "othersite.example.com"]
      });

  });
  }
}