import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PopUpNotificationResponseType } from '@fgb/core';

@Component({
  selector: 'fgb-popup-action-ok-cancel',
  templateUrl: './popup-action-ok-cancel.component.html',
  styleUrls: ['./popup-action-ok-cancel.component.scss']
})
export class PopupActionOkCancelComponent implements OnInit {

  @Output() popupResponse = new EventEmitter<PopUpNotificationResponseType>();
  responseType = PopUpNotificationResponseType;

  constructor() { }

  ngOnInit() {
  }

  emitResponse(res:PopUpNotificationResponseType): void {
    this.popupResponse.emit(res);
  }
}
