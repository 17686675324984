import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScorePredictorStatus, Survey } from '@fgb/core';

@Component({
  selector: 'fgb-score-predictor-footer',
  templateUrl: './score-predictor-footer.component.html',
  styleUrls: ['./score-predictor-footer.component.scss'],
})
export class ScorePredictorFooterComponent implements OnInit {
  @Input() survey: Survey;
  @Input() status: ScorePredictorStatus;
  @Input() canEdit: boolean;
  @Input() hasSelectedCorrect: boolean = false;
  @Input() pointsWon: number;
  @Output() editAnswer = new EventEmitter<null>();
  @Output() submitAnswer = new EventEmitter<null>();
  scorePredictorStatus = ScorePredictorStatus;

  constructor() {}

  ngOnInit(): void {}
}
