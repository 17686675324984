<div class="banner" role="banner">
  <h1>{{ 'ecash.page.title' | translate }}</h1>
</div>

<!--nav ecash & renewal bonus transfer-->
<ul class="nav nav-secondary mixed-loyalty-nav mt-0 mb-3">
  <a class="nav-item btn" [routerLink]="['/ecash']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <div class="icon svg activity ml-2 my-0" [inlineSVG]="'assets/images/icons/money-bag.svg'"></div>
    <label>{{ 'nav.ecash' | translate }}</label>
  </a>
  <a
    class="nav-item btn"
    [routerLink]="['/ecash/bonustransfer']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <div class="icon icon-swap-horizontal ml-2 my-0"></div>
    <label>{{ 'nav.bonus.transfer' | translate }}</label>
  </a>
</ul>
<!--EO: ecash & renewal bonus nav -->

<div class="container mt-4">
  <div class="row">
    <div class="col-lg-4">
      <div class="mb-3 d-block">
        <div class="mb-4 mb-lg-3">
          <fgb-member-card></fgb-member-card>
        </div>
        <div class="card balance-mini-card font-weight-bold bg-success my-3 border-0 d-none d-lg-flex">
          <div class="p-2 text-center text-white mt-1 d-flex mx-auto align-items-center">
            <div class="icon svg money-bags svg-white mr-2" [inlineSVG]="'assets/images/icons/money-bag.svg'"></div>
            {{ 'ecash.balance.bar' | translate }} {{ combinedCard$ | async | FortressCurrencyPipe }}
          </div>
        </div>
      </div>
      <fgb-topup></fgb-topup>
      <div class="mt-3">
        <fgb-autotopup></fgb-autotopup>
      </div>
    </div>
    <div class="col-lg-8 d-none d-lg-block">
      <fgb-transaction-history
        [transactionTypes]="[transactionTypes.ecash, transactionTypes.payment]"
        [ecashView]="true"
      ></fgb-transaction-history>
    </div>
  </div>
</div>
