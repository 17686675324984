import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Card, CardQuery } from '@fgb/core';

@Component({
  selector: 'fgb-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent implements OnInit {
  cards$: Observable<Card[]>;

  constructor(private cardQuery: CardQuery) { }

  ngOnInit() {
    this.cards$ = this.cardQuery.selectCards();
  }
}
