import { Component, OnInit } from '@angular/core';
import { SurveyType } from '@fgb/core';
import { SurveyQuery } from '@fgb/core';
import { Survey } from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-polls-page',
  templateUrl: './polls-page.component.html',
  styleUrls: ['./polls-page.component.scss'],
})
export class PollsPageComponent implements OnInit {
  polls$: Observable<Survey[]>;

  constructor(private surveyQuery: SurveyQuery) {}

  ngOnInit() {
    this.polls$ = this.surveyQuery.selectRelevantSurveys(SurveyType.Poll);
  }
}
