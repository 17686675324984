import { Component, OnInit, Input } from '@angular/core';
import { Survey, SurveyAnswer, AnswerDisplayType } from '@fgb/core';

@Component({
  selector: 'fgb-survey-item-answer-completed-predictor',
  templateUrl: './survey-completed-predictor.component.html',
  styleUrls: ['./survey-completed-predictor.component.scss', '../../../survey.scss']
})
export class SurveyCompletedPredictorComponent implements OnInit {
  @Input() survey: Survey;
  selectedAnswers: SurveyAnswer[] = [];
  correctAnswer: SurveyAnswer;
  hasSelectedCorrect: boolean = false;
  pointsWon: number;
  answerDisplayType = AnswerDisplayType;

  constructor() { }

  ngOnInit() {
    this.selectedAnswers = this.survey.SurveyAnswers.filter(sa => sa.UserSelected);
    if (this.survey.ProcessStatus === 2) {
      this.hasSelectedCorrect = this.selectedAnswers.filter(a => a.IsCorrectAnswer).length > 0;
      if (this.hasSelectedCorrect) {
        this.pointsWon = this.selectedAnswers[0].PointValue;
      } else {
        this.correctAnswer = this.survey.SurveyAnswers.filter(sa => sa.IsCorrectAnswer)[0];
      }
    }
  }

}
