import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-game-nav',
  templateUrl: './game-nav.component.html',
  styleUrls: ['./game-nav.component.scss'],
})
export class GameNavComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
