<ng-container [ngSwitch]="survey.ProcessStatus">
  <ng-container *ngSwitchCase="0">
    <fgb-score-predictor-completed-pending
      [survey]="survey"
      [canEdit]="canEdit"
      (editAnswer)="editAnswer.emit()"
    ></fgb-score-predictor-completed-pending>
  </ng-container>
  <ng-container *ngSwitchCase="2">
    <fgb-score-predictor-completed-processed
      [survey]="survey"
      [hasSelectedCorrect]="hasSelectedCorrect"
      [pointsWon]="pointsWon"
      class="h-100"
    ></fgb-score-predictor-completed-processed>
  </ng-container>
</ng-container>
