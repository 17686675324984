import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ExternalCashTransferViewModel, ExternalCashTransferService } from '@fgb/core';

@Component({
  selector: 'fgb-transfer-arena-multiple-accounts',
  templateUrl: './transfer-arena-multiple-accounts.component.html',
  styleUrls: ['./transfer-arena-multiple-accounts.component.scss'],
})
export class TransferArenaMultipleAccountsComponent implements OnInit {
  @Input() transferAccounts: ExternalCashTransferViewModel;
  availableMemberIds: string[];
  loading: boolean = false;
  constructor(private externalCashTransferService: ExternalCashTransferService, private router: Router) {}

  ngOnInit() {
    if (this.transferAccounts && this.transferAccounts.Accounts && this.transferAccounts.Accounts.length > 0) {
      this.availableMemberIds = this.transferAccounts.Accounts[0].MemberIDs;
    }
  }

  transferFundsToMemberId(memberId: string) {
    this.loading = true;
    this.externalCashTransferService
      .transferFunds(this.transferAccounts, memberId)
      .toPromise()
      .then(() => {
        this.router.navigate(['arenacashtransfersuccess']);
      })
      .finally(() => (this.loading = false));
  }
}
