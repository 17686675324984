import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { CompanyOfferModel, Department, DepartmentQuery, DepartmentService } from '@fgb/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'fgb-offers-map',
  templateUrl: './offers-map.component.html',
  styleUrls: ['./offers-map.component.scss'],
})
export class OffersMapComponent implements OnInit {
  @Input() offers: CompanyOfferModel[];
  @Output() selectedDepartmentID = new EventEmitter<number | undefined>();

  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @ViewChildren(MapMarker) mapMarkersEl: QueryList<MapMarker>;

  mapOptions: google.maps.MapOptions;
  mapCenter: any;
  mapZoom: any;
  mapHeight: string;
  customMarkers: any;
  mapInfo: any = {};

  constructor(
    private departmentQuery: DepartmentQuery,
    private departmentService: DepartmentService,
    private deviceDetectorService: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    this.prepareMap();
  }

  /** Centers the map on browser's location, sets map options and creates markers */
  private prepareMap(): void {
    navigator.geolocation.getCurrentPosition((position) => {
      this.mapCenter = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });

    this.mapZoom = 11;
    this.mapHeight = this.deviceDetectorService.isMobile() ? '460px' : '670px';

    this.mapOptions = {
      mapTypeId: 'roadmap',
      zoomControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: true,
      maxZoom: 15,
      minZoom: 8,
    };

    this.updateMarkers(this.offers);
  }

  /** Updates the markers shown on the map from the offers list
   * @param offer company offers to make the markers from
   */
  updateMarkers(offers: CompanyOfferModel[]): void {
    this.customMarkers = [];

    for (const offer of offers) {
      this.getDepartmentFromOffer(offer).then((dpt) => {
        if (dpt && dpt.Latitude && dpt.Longitude) {
          const alreadyPinned = this.customMarkers.some((marker: any) => marker.departmentID == dpt.DepartmentId);
          if (!alreadyPinned) {
            this.customMarkers.push({
              position: {
                lat: dpt.Latitude,
                lng: dpt.Longitude,
              },
              title: dpt.DisplayName,
              options: { animation: google.maps.Animation.DROP },
              departmentID: offer.DepartmentId,
            });
          }
        }
      });
    }
  }

  /** Zooms in the map within mapOptions range */
  zoomIn(): void {
    if (!this.mapOptions.maxZoom || this.mapZoom < this.mapOptions.maxZoom) this.mapZoom++;
  }

  /** Zooms out the map within mapOptions range */
  zoomOut(): void {
    if (!this.mapOptions.minZoom || this.mapZoom > this.mapOptions.minZoom) this.mapZoom--;
  }

  /** Centers the map on the selected offer's marker
   * @param offer company offer to center the map on
   */
  centerMap(offer: CompanyOfferModel): void {
    this.getDepartmentFromOffer(offer).then((dpt) => {
      if (dpt && dpt.Latitude && dpt.Longitude) {
        this.mapCenter = {
          lat: dpt.Latitude,
          lng: dpt.Longitude,
        };
      }
    });
  }

  /** Opens the info window from a specific marker
   * @param marker google maps marker to be used as anchor for the info window
   * @param departmentID department ID to get the info data from
   * @param emitSelection (optional) emit the selected department ID, to highlight the corresponding offers from the list
   */
  showMarkerDetails(marker: MapMarker, departmentID: number, emitSelection?: boolean): void {
    const dpt = this.departmentQuery.getDepartment(departmentID);
    this.mapInfo.title = dpt?.DisplayName;
    this.mapInfo.description = dpt?.Description;
    this.mapInfo.address = dpt?.Location;
    this.mapInfo.image = dpt?.ImageUrl;

    if (emitSelection) this.emitSelectedDepartmentID(departmentID);
    this.openInfoWindow(marker);
  }

  /** Emit the selected department ID, to highlight the corresponding offers from the list
   * @param departmentID (optional) department ID to be highlighted, undefined if none
   */
  emitSelectedDepartmentID(departmentID?: number): void {
    this.selectedDepartmentID.emit(departmentID);
  }

  /** Opens the info window corresponding to the selected marker
   * @param offer offer from which we want to display the marker's data
   */
  selectMarker(offer: CompanyOfferModel): void {
    this.closeInfoWindow();

    const marker = this.findMapMarker(offer.DepartmentId);
    if (marker) {
      this.centerMap(offer);
      this.showMarkerDetails(marker, offer.DepartmentId);
    }
  }

  /** Opens the info window
   * @param marker google maps marker to be used as anchor for the info window
   */
  private openInfoWindow(marker: MapMarker): void {
    this.infoWindow.open(marker);
  }

  /** Closes the info window and resets the department selection */
  private closeInfoWindow(): void {
    this.infoWindow.close();
    this.emitSelectedDepartmentID();
  }

  /** Fetches the departments data from the offer Company ID
   * @param offer offer from which we need to fetch the departments
   * @returns the promise of a Department or undefined, from the offer department ID
   */
  private async getDepartmentFromOffer(offer: CompanyOfferModel): Promise<Department | undefined> {
    await this.departmentService.fetchDepartments(offer.CompanyId).toPromise();
    return this.departmentQuery.getDepartment(offer.DepartmentId);
  }

  /** Looks for the map marker within the google map pins
   * @param departmentId ID of the department that should be found within the markers
   * @returns the google map pin used as anchor to display the info window
   */
  private findMapMarker(departmentId: number) {
    const markersList: any[] = this.mapMarkersEl.toArray();
    const selectedCustomMarker = this.customMarkers.find((m: any) => m.departmentID === departmentId);
    if (selectedCustomMarker) {
      return markersList.find((m) => m.marker.title == selectedCustomMarker.title);
    } else {
      return undefined;
    }
  }
}
