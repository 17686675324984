import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, OnChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { EventModel, EventsService, TopupTicketStore } from '@fgb/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'fgb-events-drop-down',
  templateUrl: './events-drop-down.component.html',
  styleUrls: ['./events-drop-down.component.scss']
})
export class EventsDropDownComponent implements OnInit, OnChanges, OnDestroy {
  @Input() eventId: string;
  @Output() eventSelected = new EventEmitter();
  selectOption: string;
  newCardSelected = false;
  events$: Observable<EventModel[]>;

  firstEvenSub: Subscription;

  constructor(
    private eventService: EventsService,
    private topupTicketStore: TopupTicketStore
  ) { }

  ngOnInit() {
    this.getAllFutureEvents();
    this.selectOption = this.eventId;

    // if there are no preselected gameNumbers / eventIds we selected the first from the list
    this.firstEvenSub = this.events$.pipe(take(1)).subscribe(events => {
      if (this.eventId === '0') {
        if (events.length > 0) {
          this.eventId = events[0].GameNumber.toString();
          this.emitSelectedEvent(this.eventId);
          this.selectOption = this.eventId;
        }
      }
    });
  }

  ngOnChanges() {
    this.selectOption = this.eventId;
  }

  ngOnDestroy() {
    if (this.firstEvenSub) {
      this.firstEvenSub.unsubscribe();
    }
  }

  public getAllFutureEvents() {
    this.events$ = this.eventService.getEvents();
  }

  selectEvent() {
    this.emitSelectedEvent(this.selectOption);
  }

  emitSelectedEvent(gameNumber: string) {
    this.topupTicketStore.clearAllTickets();
    this.eventSelected.emit(gameNumber);
  }
}
