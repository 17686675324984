import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-lottos-page',
  templateUrl: './lottos-page.component.html',
  styleUrls: ['./lottos-page.component.scss']
})
export class LottosPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
