import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ProcessStatus, ScorePredictorStatus, Survey, SurveyQuery, SurveyType } from '@fgb/core';
import { map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'fgb-score-predictor-header',
  templateUrl: './score-predictor-header.component.html',
  styleUrls: ['./score-predictor-header.component.scss'],
})
export class ScorePredictorHeaderComponent implements OnInit {
  @Input() scorePredictor: Survey;
  @Input() canEditAnswer: Boolean;
  @Input() status: ScorePredictorStatus;
  @Output() statusChange = new EventEmitter<ScorePredictorStatus>();
  @Output() removeScorePredictor = new EventEmitter<null>();
  scorePredictorStatus = ScorePredictorStatus;
  processStatus = ProcessStatus;

  constructor(private surveyQuery: SurveyQuery) {}

  ngOnInit(): void {}

  scoreClosed() {
    this.surveyQuery
      .selectSurveys(SurveyType.ScorePredictor)
      .pipe(
        take(1),
        map((surveys) => surveys.filter((item) => item.SurveyId === this.scorePredictor.SurveyId)[0]),
        tap((item) => {
          item.HasBeenAnsweredByUser ? this.statusChange.emit(ScorePredictorStatus.Answered) : this.removeScorePredictor.emit();
        })
      )
      .subscribe();
  }
}
