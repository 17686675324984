import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'fgb-auction-bid-details-page',
  templateUrl: './auction-bid-details-page.component.html',
  styleUrls: ['./auction-bid-details-page.component.scss']
})
export class AuctionBidDetailsPageComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  id: number;
  ngOnInit() {
    this.id = +(this.route.snapshot.paramMap.get('id') || 0);
  }
}
