<div class="container">
  <fgbcl-card-link-offers-list
    [cardLinkOfferOptions]="cardLinkOfferOptionsActivatedOffers"
    [labelName]="'card.linking.activated.offers.label'"
  ></fgbcl-card-link-offers-list>
</div>
<div class="container">
  <div class="d-flex align-item-center justify-content-center">
    <fgbcl-card-link-offers-list
      [cardLinkOfferOptions]="cardLinkOfferOptionsRecommendedOffers"
      [labelName]="'card.linking.offer.you.may.like.label'"
    ></fgbcl-card-link-offers-list>
  </div>
</div>
